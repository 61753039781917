import {Color, CullFaceNone, DoubleSide, Matrix4, NormalBlending, ShaderMaterial, Vector2, Vector3} from "three";
import raw from "raw.macro";
import {ThinFilmFresnelMap} from "../iridescence/ThinFilmFresnelMap";
import {Bubbles} from "./Bubbles";

const vertexShader = raw("./glsl/coke.vert.glsl");
const fragmentShader = raw("./glsl/coke.frag.glsl");
const metaInc = raw("./glsl/metaball.inc.glsl");

export class CokeBubbleMaterial extends ShaderMaterial
{
    constructor(metaballMaterial, map, sprite)
    {
        super({
            fragmentShader,
            vertexShader: vertexShader.replace("#include <metaball>", metaInc),
            uniforms: {
                lifetime: { value: 3.0 },
                growTime: { value: 3.0 },
                time: { value: 0.0 },
                map: { value: map },
                cubeMap: { value: null },
                pixelSize: { value: new Vector2() },
                threshold: { value: 0.2 },
                cameraWorldPos: { value: new Vector3() },
                viewToWorld: { value: new Matrix4( ) },
                positionMap: { value: null },
                positionMapSize: { value: new Vector2() },
                fresnelMap: { value: new ThinFilmFresnelMap( 460, 1.7,   3,  256)},
                globalScale: { value: 1.0 },
                sizeScale: { value: 1.0 }
            },
            blending: NormalBlending,
            transparent: true,
            // side: DoubleSide,
            depthWrite: !map,   // if map is provided, should not write depth
            defines: {
                SHAPE: map? Bubbles.SHAPE_BLOB : Bubbles.SHAPE_SPHERE
            }
        });

        if (metaballMaterial) {
            this.uniforms.growTime = metaballMaterial.uniforms.growTime;
            this.uniforms.lifetime = metaballMaterial.uniforms.lifetime;
            this.uniforms.time = metaballMaterial.uniforms.time;
            this.uniforms.positionMap = metaballMaterial.uniforms.positionMap;
            this.uniforms.positionMapSize = metaballMaterial.uniforms.positionMapSize;
            this.uniforms.globalScale = metaballMaterial.uniforms.globalScale;
            this.uniforms.sizeScale = metaballMaterial.uniforms.sizeScale;


            if (sprite) {
                this.uniforms.map = metaballMaterial.uniforms.map;
                this.uniforms.cubeMap = metaballMaterial.uniforms.cubeMap;
                this.uniforms.pixelSize = metaballMaterial.uniforms.pixelSize;
                this.uniforms.threshold = metaballMaterial.uniforms.threshold;
                this.uniforms.cameraWorldPos = metaballMaterial.uniforms.cameraWorldPos;
                this.uniforms.viewToWorld = metaballMaterial.uniforms.viewToWorld;
                this.uniforms.positionMap = metaballMaterial.uniforms.positionMap;
                this.uniforms.positionMapSize = metaballMaterial.uniforms.positionMapSize;
                this.uniforms.fresnelMap = metaballMaterial.uniforms.fresnelMap;

                this.defines.AS_SPRITE = "";
            }
        }
    }

    updateCamera(camera)
    {
        camera.getWorldPosition(this.uniforms.cameraWorldPos.value);
        this.uniforms.viewToWorld.value.copy(camera.matrixWorld);
    }
}