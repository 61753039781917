import {Matrix4, Vector3} from "three";

const dir = new Vector3();
const mat4 = new Matrix4();

export function getPositionWithFixedDistance(dist, group, camera, target)
{
    mat4.copy(group.matrixWorld).invert();
    // crawl around midway point
    camera.getWorldPosition(target).applyMatrix4(mat4);
    // the direction TO the camera
    dir.copy(target).normalize().multiplyScalar(dist);
    target.sub(dir);
}