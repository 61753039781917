import './Desktop.scss'
import mainImage from '../../assets/images/desktop.png'
import bg from '../../assets/images/desktop_bg.jpg'

const Desktop = () => {
    return (
        <div className="Desktop">
            <img className='bg' src={bg} alt="" />
            <img className='main' src={mainImage} alt="" />
        </div>
    );
}

export default Desktop;