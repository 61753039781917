import {
    Color, FloatType,
    HalfFloatType, LinearFilter,
    MeshBasicMaterial,
    NearestFilter,
    NoBlending,
    ShaderMaterial, Vector2, Vector4,
    WebGLRenderTarget
} from "three";
import {RenderStateStack} from "../utils/RenderStateStack";
import {renderPingPong, renderRect} from "../utils/renderRect";
import raw from "raw.macro";
import {PingPongRT} from "../utils/PingPongRT";

const fillVertex = raw("./glsl/fill.vert.glsl");
const fillFragment = raw("./glsl/fill.frag.glsl");
const updatePosVertex = raw("./glsl/updatepos.vert.glsl");
const updatePosFragment = raw("./glsl/updatepos.frag.glsl");

export class ParticleMovement
{
    constructor(numParticles, renderer, material)
    {
        this.width = Math.min(1024, numParticles);
        this.height = Math.ceil(numParticles / this.width);

        const options = {
            depth: false,
            stencil: false,
            type: FloatType,
            filter: LinearFilter
        };
        this.posBuffer = new PingPongRT(this.width, this.height, options);
        this.velBuffer = new PingPongRT(this.width, this.height, options);

        this.fillMaterial = new ShaderMaterial({
            vertexShader: fillVertex,
            fragmentShader: fillFragment,
            uniforms: {
                value: { value: new Vector4() },
                place: { value: new Vector2() },
                size: { value: new Vector2(2.0 / this.width, 2.0 / this.height) }
            }
        });

        this.renderer = renderer;
        this.velMaterial = material;

        this.posMaterial = new ShaderMaterial({
            vertexShader: updatePosVertex,
            fragmentShader: updatePosFragment,
            uniforms: {
                tVelocity: { value: null },
                tDiffuse: { value: null },
                dt: { value: 0 }
            }
        });

        const stack = new RenderStateStack(renderer);
        stack.push(this.posBuffer.renderTarget, new Color(0, 0, 0), 0);
        renderer.clear();
        stack.push(this.velBuffer.renderTarget, new Color(0, 0, 0), 0);
        renderer.clear();
        stack.pop();
        stack.pop();
    }

    get force()
    {
        return this.velMaterial.uniforms.force?.value;
    }

    set force(value)
    {
        const u = this.velMaterial.uniforms.force;

        if (u)
            u.value = value;
    }

    get positionTexture()
    {
        return this.posBuffer.sourceTexture;
    }

    get velocityTexture()
    {
        return this.velBuffer.sourceTexture;
    }

    update(dt) {
        this.velMaterial.uniforms.dt.value = dt;
        this.velMaterial.uniforms.tPosition.value = this.posBuffer.sourceTexture;
        renderPingPong(this.renderer, this.velBuffer, this.velMaterial);

        this.posMaterial.uniforms.dt.value = dt;
        this.posMaterial.uniforms.tVelocity.value = this.velBuffer.sourceTexture;
        renderPingPong(this.renderer, this.posBuffer, this.posMaterial);
    }

    setPosAt(index, x, y, z, w)
    {
        this._setAt(index, x, y, z, w, this.posBuffer);
    }

    setVelAt(index, x, y, z, w)
    {
        this._setAt(index, x, y, z, w, this.velBuffer);
    }

    _setAt(index, x, y, z, w, target)
    {
        const wi = this.width + 1;
        const py = Math.floor(index / wi);
        const px = index - py * wi;

        const uniforms = this.fillMaterial.uniforms;
        uniforms.value.value.set(x, y, z, w);
        uniforms.place.value.set(px / (this.width + 1), py / (this.height + 1));

        target.swap();
        renderRect(this.renderer, null, target.renderTarget, this.fillMaterial, false);
        target.swap();
    }
}
