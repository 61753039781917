/* eslint-disable no-undef */

import {QueryString} from "../util/QueryString";
import Emitter from "../util/Emitter";
import {ONMARKERFOUND} from "../util/constants";
import {ParticleBurst} from "../three/burst/ParticleBurst";
import Debugger from "../util/Debugger";

export const particleBurstModule = () => {
    let burst;
    let flowerBurst;
    let props = {
        bottleSpriteSize: 0.4,
        bottleSpriteScale: 2.3,
    };

    function initDebugUI()
    {
        const folder = Debugger.gui.addFolder("Particles");
        folder.add(props, "bottleSpriteSize").min(0.2).max(1.0);
        folder.add(props, "bottleSpriteScale").min(0.5).max(3.0);
    }

    return {
        name: 'particleBurstModule',
        onStart: () => {
            const {scene, renderer} = getXRScene();
            burst = new ParticleBurst();
            flowerBurst = new ParticleBurst(ParticleBurst.FlowerMode);

            initDebugUI();

            if (QueryString.debug) {
                // scene.add(burst);
                scene.add(flowerBurst);
                renderer.domElement.addEventListener("click", () => {
                    // burst.trigger();
                    flowerBurst.trigger();
                })
            }

            Emitter.on(ONMARKERFOUND, ({ name, group, scale }) => {
                    const spec = name.includes("flower")? flowerBurst : burst;
                    if (name.includes("bottle")) {

                        burst.size = props.bottleSpriteSize;
                        burst.scale.setScalar(props.bottleSpriteScale*scale);
                    }
                    else {
                        burst.size = 0.2;
                        burst.scale.setScalar(1);
                    }

                    group.add(spec);
                    spec.trigger();
            });
        },
        onUpdate: () => {
            const {camera, delta} = getXRScene();

            if (burst?.parent)
                burst.update(camera, delta);

            if (flowerBurst?.parent)
                flowerBurst.update(camera, delta);
        },


    }
}

function getXRScene()
{
    return window.XR8?.Threejs?.xrScene() ?? window.Stage3D
}
