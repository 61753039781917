// import Postprocessing from './Postprocessing'
// import {autobind} from "core-decorators";
// import {ONASSETSLOADED, ONMARKERFOUND, ONPLAYERREADY, ONSTART} from "../core/constants";
import Emitter from "../util/Emitter";
import * as THREE from 'three'
import {DRAW_BUBBLE, ONCANVASRESIZE, ONCHANGESCENE, ONMARKERBLOCK, ONMARKERFOUND, ONMARKERLOST, ONMARKERUNBLOCK} from "../util/constants";
import {DaytimeEnvMapController} from "../three/cubeMapMixer/DaytimeEnvMapController";
import Debugger from "../util/Debugger";
import Global from "../util/Global";

const WebARScene = () => {
    let bubbleGroup;
    let envMap;
    let _stage3D;
    let blockMarker = false;
    let globalMarkerBlocked = false;
    let bottleColdDown = 15; //seconds before being able to scan the bottle again

    // ['kami_blue_left', 'color_bottle_right', 'color_bottle_left', 'doomdoom', 'flower_v2'],
    //! -1 = practice / 0 = day / 1 = night / 2 = ASMR / 3 = flowers / 4 = end.
    const arMarkers =  [
        ['kami_instructions', 'doomdoom', 'doomdoom_instructions', 'flower_instructions', 'color_bottle_instructions'], //onlyInstructions
        ['kami_left_bg', 'kami_rigth_bg', 'doomdoom_right', 'doomdoom_left'], //only monsters
        // ['kami_left_bg', 'kami_rigth_bg', 'doomdoom_right', 'doomdoom_left'], //only monsters
        ['color_bottle_top_right', 'color_bottle_top_left'], //ARMS just in case
        ['color_bottle_top_right', 'color_bottle_top_left'], //Only bottles 'color_bottle_top_right', 'color_bottle_top_left'
        ['flower_v2', 'flower_instructions'], //Only flowers
        [''],
    ]

    const initXrScene = (stage3D) => {
        _stage3D = stage3D;
        const { scene, camera, renderer } = stage3D;

        //init Markers
        let _index = parseInt(Global.markerIndex) + 1
        window.XR8.XrController.configure({imageTargets: arMarkers[_index]})

        console.log(_index);
        console.log(arMarkers[_index]);
        console.log(arMarkers);

        // Add a random bubbleGroup in space to test marker //
        let testCube = new THREE.Mesh(new THREE.BoxBufferGeometry(0.2, 0.2, 0.2), new THREE.MeshStandardMaterial({ color: 'blue', side: THREE.DoubleSide, roughness: 0, metalness: 0 }))
        bubbleGroup = new THREE.Group();
        // bubbleGroup.add(testCube);
        bubbleGroup.position.set(0, 0.3, -2)
        bubbleGroup.castShadow = false;
        bubbleGroup.visible = false;

        scene.add(bubbleGroup);

        // Add basic lightning
        scene.add(new THREE.AmbientLight(0x404040, 0.5))
        const directionalLight = new THREE.DirectionalLight(0xffffff, 1)
        directionalLight.position.set(5, 10, 7)
        directionalLight.castShadow = true

        scene.add(directionalLight)


        // Add a plane that can receive shadows.
        const planeGeometry = new THREE.PlaneGeometry(2000, 2000)
        planeGeometry.rotateX(-Math.PI / 2)
        const planeMaterial = new THREE.ShadowMaterial()
        planeMaterial.opacity = 0.67
        const plane = new THREE.Mesh(planeGeometry, planeMaterial)
        plane.receiveShadow = true
        // scene.add(plane)

        //add temp floating elements around the user.
        // addFloatingBubbles(scene);

        camera.position.set(0, 2, 0)

        envMap = new DaytimeEnvMapController(renderer, [
            window.AssetLoader.getTexture("envmap/day").media,
            window.AssetLoader.getTexture("envmap/sunset").media,
            window.AssetLoader.getTexture("envmap/night").media,
            window.AssetLoader.getTexture("envmap/dawn").media,
        ]);
        envMap.daytime = 0.5;

        stage3D.envMap = envMap.texture;

        configDebugTool();
    }

    const configDebugTool = () => {
        const markersFolder = Debugger.gui.addFolder("markers");
        var markers = {marker: '-1'};
        markersFolder.add(markers, "marker",{tutorial: '-1', monsters: '0', bottles: '1', arsm: '2', flowers: '3', end: '5'}).onChange((value)=>{
            Emitter.emit(ONCHANGESCENE,value)
        })
    }

    // Places content over image target
    const showTarget = ({ detail }) => {

        // TODO: emmit event for david with (detected, {group: group})
        //AR MARKER
        // if (detail.name === 'marker_name') {
        if(blockMarker) return
        if(globalMarkerBlocked) return
        if (Global.isFirstTutorialEnabled) return
        if (detail.name.includes('color_bottle')) {
            bubbleGroup.position.copy(detail.position)
            bubbleGroup.quaternion.copy(detail.rotation)

            // bubbleGroup.scale.set(detail.scale, detail.scale, detail.scale)

            // if (!bubbleGroup.visible) {
                Emitter.emit(ONMARKERFOUND, {name: detail.name, group:bubbleGroup, scale: detail.scale});
                blockMarker = true;
                // temp, allow the marker to be scannable again after 10 seconds
                setTimeout(() => {
                    blockMarker = false;
                    // bubbleGroup.visible = false
                }, bottleColdDown*1000);
            // }

            bubbleGroup.visible = true

            // Emitter.emit("marker_found", detail.name);
        }
    }

    const updateTarget = ({ detail }) => {

        if (detail.name.includes('color_bottle')) {
            bubbleGroup.position.copy(detail.position)
            bubbleGroup.quaternion.copy(detail.rotation)
            // bubbleGroup.scale.set(detail.scale, detail.scale, detail.scale)
            // Emitter.emit("marker_found", detail.name);
        }
    }



    // Hides the image frame when the target is no longer detected.
    const hideTarget = ({ detail }) => {
        // if (detail.name === 'coke_zs_325ml_flat' || detail.name === 'test-marker') {
        if (detail.name.includes('color_bottle')) {
            // bubbleGroup.position.copy(detail.position)
            // bubbleGroup.quaternion.copy(detail.rotation)
            // bubbleGroup.scale.set(detail.scale, detail.scale, detail.scale)

            Emitter.emit(ONMARKERLOST, {name: detail.name, group:bubbleGroup});
            // if (bubbleGroup.visible) {
            //     bubbleGroup.visible = false
            // }
        }
    }

    // Grab a handle to the threejs scene and set the camera position on pipeline startup.
    const onStart = ({ canvas, GLctx }) => {
        console.log('onstart', window.XR8.Threejs.xrScene());
        const stage3D = window.XR8.Threejs.xrScene();
        const { scene, camera } = stage3D  // Get the 3js scene from XR8.Threejs

        initXrScene(stage3D)  // Add content to the scene and set starting camera position.

        //add Event to handle image target change
        Emitter.on(ONCHANGESCENE,(data)=>{
            console.log(data, data != -1);
            if(data != -1){
                setTimeout(() => {
                    Global.isInstructions = false;
                }, 3000);
            }else{
                Global.isInstructions = true;
            }

            if(data == 3 || data == 0){
                window.XR8.XrController.recenter()
            }

            Global.markerIndex = data
            //! -1 = practice / 0 = day / 1 = night / 2 = ASMR / 3 = flowers / 4 = end.
            let _index = parseInt(data) + 1
            if(!arMarkers[_index]) return
            window.XR8.XrController.configure({imageTargets: arMarkers[_index]})
            console.log(arMarkers[_index], Global.isInstructions);
        })

        Emitter.on(ONMARKERBLOCK,()=>{
            globalMarkerBlocked = true
            console.log("block bottle");
        })

        Emitter.on(ONMARKERUNBLOCK,()=>{
            globalMarkerBlocked = false
            console.log("block bottle");

            // blockMarker = false;
        })

        // prevent scroll/pinch gestures on canvas
        canvas.addEventListener('touchmove', (event) => {
            event.preventDefault()
        })

        // Emitter.emit(DRAW_BUBBLE, handRenderer.indexFingerMesh.getWorldPosition(v));

        const folder = Debugger.gui.addFolder("Env Map");
        folder.add(envMap, "daytime").min(0).max(1)


        // Sync the xr controller's 6DoF position and camera paremeters with our scene.
        window.XR8.XrController.updateCameraProjectionMatrix({
            origin: camera.position,
            facing: camera.quaternion,
        })
    }

    const onRender = () => {
    }

    const onUpdate = () => {
        envMap.update();
    }

    return {
        // Camera pipeline modules need a name. It can be whatever you want but must be
        // unique within your app.
        name: 'general-ar-tracking-scene',

        onStart,
        onRender,
        onUpdate,
        onCanvasSizeChange: ({ canvasWidth, canvasHeight }) => {
            const stage3D = window.XR8.Threejs.xrScene();
            stage3D.resize(canvasWidth, canvasHeight);
            stage3D.post?.composer?.setSize(canvasWidth, canvasHeight);
            Emitter.emit(ONCANVASRESIZE, { canvasWidth, canvasHeight })
        },

        // Listeners are called right after the processing stage that fired them. This guarantees that
        // updates can be applied at an appropriate synchronized point in the rendering cycle.
        listeners: [
            { event: 'reality.imagefound', process: showTarget },
            // { event: 'reality.imageupdated', process: updateTarget }, //DAVID: you can disable this to rely only on SLAM
            { event: 'reality.imagelost', process: hideTarget },
        ],
    }
}

export default WebARScene;