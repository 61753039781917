import { io } from 'socket.io-client'
import { ONCHANGESCENE } from '../util/constants';
import Emitter from '../util/Emitter';
import Global from '../util/Global';
import { QueryString } from '../util/QueryString';

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const isOnSite = urlParams.get('onSite') ? true : false;

const clientAPI = {
  // serverURL: 'ws://3.208.18.17:80',
  serverURL: "wss://coachella.toolofna.com:443",
  pathToAPI: '/',
  timer: "",
  scene: -1,
  socket: null,
  currentTime: 0,
  startTime: null,
  timerEl: null,
  timerId: null,

  handleSceneChange(event) {
    console.log(event);
    let _scene = event.scene; // -1 = practice / 0 = day / 1 = night / 2 = ASMR / 3 = flowers / 4 = end.

    Global.markerIndex = _scene
    Emitter.emit(ONCHANGESCENE,_scene)

    if(_scene == '0'){
      this.startTimer()
    }

    if(this.sceneEl)this.sceneEl.innerHTML = Global.markerIndex
  },

  addDebugUI(){
    this.sceneEl = document.createElement('p')
    this.sceneEl.setAttribute('id',"scene-indicator")
    this.sceneEl.style = `
    position: absolute;
    z-index: 1000;
    top: 40px;
    left: 20px;
    background: black;
    color: white;`

    this.timerEl = document.getElementById("timer") || document.createElement('p')
    this.timerEl.setAttribute('id',"timer")
    this.timerEl.style = `
      position: absolute;
      z-index: 1000;
      top: 10px;
      left: 20px;
      background: black;
      color: white;`
    document.documentElement.append(this.timerEl);
    document.documentElement.append(this.sceneEl);
    if(this.sceneEl)this.sceneEl.innerHTML = Global.markerIndex
  },

  startTimer() {
    this.startTime = Date.now();
    this.timerId = requestAnimationFrame(this.updateTimer.bind(this));
  },

  stopTimer() {
    cancelAnimationFrame(this.timerId);
  },

  updateTimer() {
    const currentTime = Date.now();
    const elapsedTime = currentTime - this.startTime;
    const seconds = Math.floor(elapsedTime / 1000);

    this.currentTime = seconds
    if(this.timerEl) this.timerEl.innerHTML = seconds;

    this.timerId = requestAnimationFrame(this.updateTimer.bind(this));
  },

  connect() {
      //! TEST ONLY
      console.log("connecting");
      if (QueryString.dev) {
        this.addDebugUI()
      }

      // setTimeout(() => {
      //   this.handleSceneChange({scene:3})
      // }, 2000);

      //! ////////////////// !/
    // this.startTimer()
    // this is communication with Tool AWS API
    this.socket = io(this.serverURL, { rejectUnauthorized: false })



    // subscribe to client messages from API
    this.socket.emit("register-client")

    this.socket.on("connection", (socket) => {
      console.log(socket); // world
      console.log("hellow"); // world
    });
    // recieve count start message
    // todo: include selected countdown message 2 3 or 4 mins.

    // ? /Composition/columns/[1]/connect  where  [#] =
    // ? /Composition/columns/[2]/connect  where  [#] =
    // ? /Composition/columns/[3]/connect  where  [#] =
    // /Composition/columns/[4]/connect
    // /Composition/columns/[6]/connect

    // ? /Composition/columns/time = 0  where  [#] =

    // ? /Composition/columns/[7]/connect  where  [#] = EMERGENCY
    this.socket.on("countdown-start", (msg) => {
      // placeholder function
      // abstraced for testing
      this.countDownStart()
    })

    // recieve play message
    this.socket.on("time-event", (msg) => {
      console.log("Start experience");
      console.log(msg);
      // set state
      // start animations
    })


    // recieve other triggers
    // juan, let us know exactly which you want, and their timestamps :)
    // ? -1 = practice / 0 = day / 1 = night / 2 = ASMR / 3 = flowers / 4 = end.
    // ?msg = {scene: 0, timeStamp: 00:00}
    this.socket.on("scene", (msg) => {

      this.handleSceneChange(msg)

    })

  },

  // example of abstracted function for testing locally
  countDownStart() {
    // set scene changes
    // start animations
    // start timer
  }
}

export default clientAPI;