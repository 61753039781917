import dat from 'dat.gui'
import Global from './Global'
import {QueryString} from "../util/QueryString";

class Debugger {
    constructor() {
        this.gui = new dat.GUI({
            hideable: Global.gui
        });
        this.params = {};

        if (!QueryString.dev) {
            this.gui.hide()
        }
    }
}

export default new Debugger()