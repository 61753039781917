import {
    AddEquation,
    Color, CustomBlending, Group,
    HalfFloatType,
    InstancedBufferAttribute,
    InstancedBufferGeometry,
    Mesh, OneFactor,
    PlaneBufferGeometry, SphereBufferGeometry, Sprite, SpriteMaterial, SrcAlphaFactor,
    WebGLRenderTarget,
} from "three";
import {RenderStateStack} from "../utils/RenderStateStack";
import {CurlNoiseMovement} from "./CurlNoiseMovement";
import {MetaballMaterial} from "./MetaballMaterial";
import {CokeBubbleMaterial} from "./CokeBubbleMaterial";
import {ParticleMovement} from "./ParticleMovement";
import {OrbitMovement} from "./OrbitMovement";

const BLACK = new Color(0, 0, 0);

// TODO: Render to screen could probably just be a quad? Do we need depth?
export class Bubbles extends Mesh
{
    static MODE_CURL_NOISE = 0;
    static MODE_SINGLE_BLOB = 1;

    static SHAPE_SPHERE = 0;
    static SHAPE_BLOB = 1;

    spawnIndex = 0;
    time = 0;
    frameCount = 0;
    stagger = 2;
    _mode = Bubbles.MODE_CURL_NOISE;
    _maskAlpha = 1.0;

    constructor(renderer, maxCount = 200, scale = .25, shape = Bubbles.SHAPE_SPHERE)
    {
        super();

        this._shape = shape;
        this.renderables = [];

        if (shape === Bubbles.SHAPE_BLOB) {
            this.rt = new WebGLRenderTarget(1, 1, {depthBuffer: false, stencilBuffer: false, type: HalfFloatType});
            this.metaMaterial = new MetaballMaterial();
            this.renderMaterial = new CokeBubbleMaterial(this.metaMaterial, this.rt.texture);
            this.uniforms = this.metaMaterial.uniforms;
        }
        else {
            this.renderMaterial = new CokeBubbleMaterial(null, null);
            this.uniforms = this.renderMaterial.uniforms;
        }

        this.material = this.renderMaterial;
        this.renderables.push({
            object: this,
            metaMaterial: this.metaMaterial,
            renderMaterial: this.renderMaterial
        })

        this.frustumCulled = false;

        this.maxCount = maxCount;
        this.screenScale = scale;
        this._renderer = renderer;
        this._renderStack = new RenderStateStack(renderer);
        this._curl = new CurlNoiseMovement();
        this._orbit = new OrbitMovement();
        this._movement = new ParticleMovement(maxCount, renderer, this._curl);

        this.uniforms.positionMapSize.value.set(this._movement.width, this._movement.height)
        this.mask = new Group();
        this.add(this.mask);

        this.initGeometry();
    }

    get globalScale()
    {
        return this.uniforms.globalScale.value;
    }

    set globalScale(value)
    {
        this.uniforms.globalScale.value = value;
    }

    get sizeScale()
    {
        return this.uniforms.sizeScale.value;
    }

    set sizeScale(value)
    {
        this.uniforms.sizeScale.value = value;
    }

    addMask(map)
    {
        const sprite = new Sprite();
        this.mask.add(sprite);
        this.renderables.push({
            object: sprite,
            metaMaterial: new SpriteMaterial({
                map,
                transparent: true,
                blending: CustomBlending,
                blendSrc: SrcAlphaFactor,
                blendDst: OneFactor,
                blendSrcAlpha: OneFactor,
                blendDstAlpha: OneFactor,
                blendEquation: AddEquation,
                blendEquationAlpha: AddEquation,
                depthWrite: false,
            }),
            renderMaterial: new CokeBubbleMaterial(this.renderMaterial, this.rt.texture, true)
        });
        return sprite;
    }

    get maskAlpha()
    {
        return this._maskAlpha;
    }

    set maskAlpha(value)
    {
        this._maskAlpha = value;
        this.renderables.forEach((mask, i) => {
            if (i === 0) return;
            mask.metaMaterial.opacity = value;
        })
    }

    get mode()
    {
        return this._mode;
    }

    set mode(value)
    {
        this._mode = value;

        if (value === Bubbles.MODE_CURL_NOISE)
            this._movement.velMaterial = this._curl;
        else if (value === Bubbles.MODE_SINGLE_BLOB)
            this._movement.velMaterial = this._orbit;
    }

    get envMap()
    {
        return this.renderMaterial.uniforms.cubeMap.value;
    }

    set envMap(value)
    {
        this.renderMaterial.uniforms.cubeMap.value = value;
    }

    get force()
    {
        return this._movement.velMaterial.uniforms.force.value;
    }

    set force(value)
    {
        this._movement.velMaterial.uniforms.force.value = value;
    }

    spawn(pos, size = 2, vel = null)
    {
        if ((this.frameCount++ % this.stagger) !== 0) return;
        const index = (this.spawnIndex++) % this.maxCount;

        this._movement.setPosAt(index, pos.x, pos.y, pos.z, size);

        if (vel)
            this._movement.setVelAt(index, vel.x, vel.y, vel.z, 1.0);
        else
            this._movement.setVelAt(index, 0.0, 0.0, 0.0, 1.0);

        this.spawnAttrib.array[index] = this.time;
        this.spawnAttrib.needsUpdate = true;

        this.geometry.instanceCount = Math.min(this.spawnIndex, this.maxCount);
    }

    setVelocity(index, value)
    {
        if (index < this.maxCount)
            this._movement.setVelAt(index, value.x, value.y, value.z, 1.0);
    }

    get lifetime()
    {
        return this.uniforms.lifetime.value;
    }

    set lifetime(value)
    {
        this.uniforms.lifetime.value = value;
    }

    get growTime()
    {
        return this.uniforms.growTime.value;
    }

    set growTime(value)
    {
        this.uniforms.growTime.value = value;
    }

    update(camera, dt)
    {
        this.time += dt;

        this.renderMaterial.updateCamera(camera);

        this._movement.update(dt);

        const unifs = this.uniforms;
        unifs.positionMap.value = this._movement.positionTexture;
        unifs.time.value = this.time;

        if (this._shape === Bubbles.SHAPE_BLOB) {
            this._updateRT();
            this._renderStack.push(this.rt, BLACK, 0);

            this.renderables.forEach(mask => mask.object.material = mask.metaMaterial);
            this._renderer.autoClear = true;
            this._renderer.render(this, camera);
            this.renderables.forEach(mask => mask.object.material = mask.renderMaterial);
            this._renderStack.pop();
        }
    }

    _updateRT()
    {
        const w = Math.floor(this._renderer.domElement.width * this.screenScale);
        const h = Math.floor(this._renderer.domElement.height * this.screenScale);
        if (w !== this.rt.width || h !== this.rt.height) {
            this.rt.setSize(w, h);
            this.renderMaterial.uniforms.pixelSize.value.set(1.0 / w, 1.0 / h);
        }
    }

    initGeometry()
    {
        const count = this.maxCount;
        const width = this._movement.width;
        const height = this._movement.height;
        const particleIndices = new Float32Array(count);
        const particleUVs = new Float32Array(count * 2);

        let x = 0, y = 0;
        for (let i = 0; i < count; ++i) {
            const i2 = i << 1;
            particleUVs[i2] = x / width;
            particleUVs[i2 + 1] = y / height;
            particleIndices[i] = i;

            if (++x === width) {
                x = 0;
                ++y;
            }

        }

        this.spawnAttrib = new InstancedBufferAttribute(new Float32Array(count), 1, false);

        const baseGeom = this._shape === Bubbles.SHAPE_BLOB ? new PlaneBufferGeometry() : new SphereBufferGeometry(.25);
        const geom = new InstancedBufferGeometry().copy(baseGeom);
        geom.instanceCount = 0;
        geom.setAttribute("spawnTime", this.spawnAttrib);
        geom.setAttribute("particleID", new InstancedBufferAttribute(particleIndices, 1, false));
        geom.setAttribute("particleUV", new InstancedBufferAttribute(particleUVs, 2, false));
        this.geometry = geom;
    }
}