import {Bubbles} from "./Bubbles";
import {BuildBlobStreamPhase} from "./BuildBlobStreamPhase";

export class BubbleExplosion extends Bubbles
{
    static PhaseNone = null;
    static PhaseBuild = new BuildBlobStreamPhase();
    static PhaseEffervescent = new BuildBlobStreamPhase(true);
    // static PhaseExplode = new ExplodePhase();

    constructor(renderer)
    {
        super(renderer);
        this._phase = BubbleExplosion.PhaseNone;
        this.mode = Bubbles.MODE_SINGLE_BLOB;
    }

    reset()
    {
        this.phase = BubbleExplosion.PhaseNone;
        this.time = 0;
        this.frameCount = 0;
    }

    get phase()
    {
        return this._phase;
    }

    set phase(value)
    {
        this._phase = value;
        if (value) {
            this._phase.reset();
            this.mode = value.movementMode;
            this.lifetime = value.lifetime;
            this.growTime = value.growTime;
        }
    }

    update(camera, dt)
    {
        this._phase?.update(camera, this, dt);
        this._movement.force = this._phase?.force ?? 1;
        super.update(camera, dt);
    }
}