import './App.scss';
import React from 'react';
import ARSceneComponent from './8thwall/ARSceneComponent';
import UI from './components/UI/UI';
import { useStore } from './hook/useStore';
import Emitter from './util/Emitter';
import { useEffect } from 'react';
import {ONFAMILYSELECTED} from './util/constants';
import Loading from './components/Loading/Loading';
import Desktop from './components/ErrorUI/Desktop';
import Global from './util/Global';
import clientAPI from './module/clientAPI';
import BreadCrumb from './components/BreadCrumb/BreadCrumb';

function App() {
  const [{isMobile, isOnLocation, startAR}, {setStartAR}] = useStore();

  useEffect(() => {
    console.log("conecting");
    clientAPI.connect()
  }, []);

  return (
    <div className="App">
      {startAR?
      <React.Fragment>
        <ARSceneComponent/>
        <BreadCrumb/>
      </React.Fragment>
      :''}
      {isMobile?<UI/>:<Desktop/>}
      {startAR&&isMobile?<Loading/>:''}
    </div>
  );
}

export default App;
