// hand3D = window.AssetLoader.getModel('hand-r').media.scene.children[0]
import * as THREE from 'three'
import gsap from 'gsap'
import { ONCHANGESCENE, ONMARKERBLOCK, ONMARKERFOUND, ONMARKERLOST, ONMARKERUNBLOCK } from '../../util/constants';
import Emitter from '../../util/Emitter';
import Debugger from '../../util/Debugger';
import FlowerSpawner from './Flowers/FlowerSpawner';
import Global from '../../util/Global';

// TODO: Refactor each monster into its own class.
// TODO: Refactor to be a trackerManager
const imageTrackingMonsters = () => {
    let time = Date.now();
    let doomdoom, redHead, puppetBag, kami
    let doomdoomTimer, kamiTimer;
    let monsterMap;
    let vfxGroup;
    let camTargetPostion
    let _camera;
    let debugVars = { timeout: 7 }
    let friendMarkerActive = false;
    let flowerManager

    const initXrScene = (stage3D) => {
        const { scene, camera, content } = stage3D;
        _camera = camera;

        vfxGroup = new THREE.Group();
        vfxGroup.scale.setScalar(2);
        flowerManager = new FlowerSpawner(scene, camera)

        doomdoom = window.AssetLoader.getModel('doomdoom').media.scene
        doomdoom.clips = window.AssetLoader.getModel('doomdoom').media.animations
        doomdoom.mixer = new THREE.AnimationMixer(doomdoom);
        doomdoom.children[0].scale.setScalar(1.8)
        doomdoom.children[0].position.y -= 0.2

        doomdoom.flyingAnim = doomdoom.mixer.clipAction(doomdoom.clips[3])
        doomdoom.idleAnimation = doomdoom.mixer.clipAction(doomdoom.clips[0])
        doomdoom.poppingAnim = doomdoom.mixer.clipAction(doomdoom.clips[1])
        // doomdoom.poppingAnim.clampWhenFinished = true
        doomdoom.poppingAnim.setLoop(THREE.LoopOnce)

        puppetBag = window.AssetLoader.getModel('puppet_bag').media.scene
        puppetBag.clips = window.AssetLoader.getModel('puppet_bag').media.animations
        puppetBag.mixer = new THREE.AnimationMixer(puppetBag);

        // Kami monster
        kami = window.AssetLoader.getModel('kami_green').media.scene
        kami.mixer = new THREE.AnimationMixer(kami);
        kami.clips = window.AssetLoader.getModel('kami_green').media.animations


        kami.flyingAnim = kami.mixer.clipAction(kami.clips[0])
        kami.idleAnimation = kami.mixer.clipAction(kami.clips[1])
        kami.poppingAnim = kami.mixer.clipAction(kami.clips[2])
        kami.poppingAnim.clampWhenFinished = true
        kami.poppingAnim.setLoop(THREE.LoopOnce)

        doomdoom.visible = false;
        puppetBag.visible = false;
        kami.visible = false;


        camTargetPostion = new THREE.Group();

        // ! for debugging only
        // camTargetPostion.add(new THREE.Mesh(new THREE.BoxGeometry(0.01,0.01,0.01), new THREE.MeshNormalMaterial()))

        camera.add(camTargetPostion)
        camTargetPostion.position.z = -1.7
        camTargetPostion.position.x = 0
        camTargetPostion.position.y = -0.1

        monsterMap = {
            'doomdoom': { obj: doomdoom, scanned: false, isAnimating: false },
            'kami': { obj: kami, scanned: false, isAnimating: false },
            'flower': { obj: doomdoom, scanned: false, isAnimating: false },
            'coke': { obj: puppetBag, scanned: false, isAnimating: false },
            // 'yellow_flower' : redHead,

        }
        scene.add(vfxGroup, doomdoom, puppetBag, kami)


        initDebugUI()
        Emitter.on(ONCHANGESCENE,(data)=>{
            if(data == 0){
                friendMarkerActive = true
                setTimeout(() => {
                    monsterMap['doomdoom'].scanned = false;
                    monsterMap['doomdoom'].isAnimating = false;
                    monsterMap['doomdoom'].obj.visible = false;
                    monsterMap['kami'].scanned = false;
                    monsterMap['kami'].isAnimating = false;
                    monsterMap['kami'].obj.visible = false;
                    friendMarkerActive = false;
                    Emitter.emit(ONMARKERUNBLOCK)
                }, 1000);
            }

            if(data == 4){
                flowerManager.killAllFlowers()
            }
        })
        // console.log(doomdoom);
    }

    const initDebugUI = () => {
        // const folder = Debugger.gui.addFolder("AR Marker");
        Debugger.gui.add(debugVars, 'timeout', 3, 15, 1).name('friends duration');
    }

    const applyMarkerPosition = (_object, detail) => {
        _object.position.copy(detail.position)
        _object.quaternion.copy(detail.rotation)
        // _object.scale.set(detail.scale, detail.scale, detail.scale)
    }

    const applyPositionToVFX = (_object, detail, scale) => {
        _object.position.copy(detail.position)
        _object.quaternion.copy(detail.rotation)
        _object.scale.set(detail.scale * scale, detail.scale * scale, detail.scale * scale)
    }

    // Places content over image target
    const showTarget = ({ detail }) => {
        console.log(detail.name);
        console.log(Global.isInstructions,monsterMap)
        console.log(friendMarkerActive)
        if (Global.isFirstTutorialEnabled) return
        if (friendMarkerActive && Global.isInstructions) return

        if (detail.name) {
            if (detail.name.includes('color_bottle')){
                Emitter.emit(ONMARKERBLOCK)
                friendMarkerActive = true;
                setTimeout(() => {
                    friendMarkerActive = false;
                    Emitter.emit(ONMARKERUNBLOCK)
                }, 5000);
                return
            }

            // TODO: MOVE TO THE END OF CALLBACK

            if (detail.name.includes('flower')) {
                if (friendMarkerActive) return

                applyPositionToVFX(vfxGroup, detail, 1.4)
                if (Global.isInstructions) {
                    let flowTemp = monsterMap['flower']
                    if(flowTemp.scanned)return
                    Emitter.emit(ONMARKERBLOCK)
                    friendMarkerActive = true;
                    flowTemp.scanned = true
                    flowerManager.spawnOnTutorial(detail)
                    setTimeout(() => {
                        friendMarkerActive = false;
                        Emitter.emit(ONMARKERUNBLOCK)
                    }, 4000);

                    setTimeout(() => {
                        flowTemp.scanned = false;
                    }, 15000);

                } else {
                    flowerManager.onMarkerDetected(detail)
                    Emitter.emit(ONMARKERBLOCK)
                    friendMarkerActive = true;
                    setTimeout(() => {
                        friendMarkerActive = false;
                        Emitter.emit(ONMARKERUNBLOCK)
                        flowerManager.stopSpawning()
                    }, 10000);
                }
            } else {
                applyPositionToVFX(vfxGroup, detail, 2.5)
                let monster; //monsterMap[detail.name];
                if (detail.name.includes('kami')) {
                    monster = monsterMap['kami'];
                } else if (detail.name.includes('doomdoom')) {
                    monster = monsterMap['doomdoom'];
                }


                if (!monster) return
                if (monster.isAnimating) return;
                if (monster.scanned) return;
                Emitter.emit(ONMARKERBLOCK)
                friendMarkerActive = true;
                applyMarkerPosition(monster.obj, detail)


                // monster.obj.position.z= _camera.position.z - 4 make this a better position always z distance from camera.
                let posVector = new THREE.Vector3()
                // const dir = posVector.sub(_camera.position).normalize();
                // const dir = posVector.subVectors( detail.position, _camera.position ).normalize();
                // const distance = (_camera.position.z - 4) / dir.z;
                // const finalPos = _camera.position.clone().add(dir.multiplyScalar(distance));

                // monster.obj.position.copy(finalPos)
                monster.obj.visible = true

                if (detail.name.includes('doomdoom')) {
                    doomDoomDetected(monster)
                    // monster.scanned = true;
                }
                if (detail.name.includes('kami')) {
                    // doomDoomDetected(monster)
                    kamiDetected(monster)
                    // monster.scanned = true;
                }
            }
            Emitter.emit(ONMARKERFOUND, { name: detail.name, group: vfxGroup });
            vfxGroup.visible = true
        }
    }

    // Hides the image frame when the target is no longer detected.
    const hideTarget = ({ detail }) => {
        // if (detail.name === 'coke_zs_325ml_flat' || detail.name === 'test-marker') {
        if (detail.name) {
            let monster; // = monsterMap[detail.name];
            if (detail.name.includes('kami_blue')) {
                monster = monsterMap['kami'];
            } else if (detail.name.includes('doomdoom')) {
                monster = monsterMap['doomdoom'];
            }

            if (!monster) return
            if (monster.isAnimating) return;
            if (monster.scanned) return;

            // monster.obj.position.copy(detail.position)
            // monster.obj.quaternion.copy(detail.rotation)
            // monster.obj.scale.set(detail.scale, detail.scale, detail.scale)

            // applyMarkerPosition(monster.obj, detail)
            // applyPositionToVFX(vfxGroup, detail)

            if (monster.obj.visible) {
                Emitter.emit(ONMARKERLOST, { name: detail.name, group: vfxGroup });
            }

            monster.obj.visible = false
            vfxGroup.visible = false
        }
    }

    // Grab a handle to the threejs scene and set the camera position on pipeline startup.
    const onStart = ({ canvas, GLctx }) => {
        const stage3D = window.XR8.Threejs.xrScene();
        const { scene, camera } = stage3D  // Get the 3js scene from XR8.Threejs

        initXrScene(stage3D)  // Add content to the scene and set starting camera position.
        console.log("complete", doomdoom);
        // console.log("complete",window.AssetLoader.getModel('doomdoom').media);
    }

    const updateTarget = ({ detail }) => {
        if (detail.name) {
            // let monster = monsterMap[detail.name];
            // if(!monster) return

            // applyMarkerPosition(monster.obj, detail)
            // applyMarkerPosition(vfxGroup, detail)
        }
    }

    const doomDoomDetected = (monster) => {
        // doomdoom.

        monster.scanned = true;
        monster.isAnimating = true;

        let target = new THREE.Vector3();
        camTargetPostion.getWorldPosition(target);

        doomdoom.lookAt(_camera.position);

        //for path moving https://observablehq.com/@rveciana/three-js-object-moving-object-along-path for moving.
        // rotation on path:
        // tangent = spline.getTangent( t ).normalize();
        // axis.crossVectors( up, tangent ).normalize();
        // radians = Math.acos( up.dot( tangent ) );
        // marker.quaternion.setFromAxisAngle( axis, radians );

        let showupTL = gsap.timeline()
        doomdoom.traverse(el => {
            if (el.isMesh && el.material) {
                el.material.transparent = true
                console.log(el);
                // showupTL.fromTo(el.material,{opacity:0},{duration: 1, opacity:1})
                showupTL.fromTo(el.material, { opacity: 0 }, {
                    duration: 0.3, opacity: 1,
                    onStart: () => {
                        doomdoom.poppingAnim.reset();
                        doomdoom.poppingAnim.play();

                        doomdoom.readyToFollow = true;
                        doomdoom.following = true;
                    },
                    onComplete:() =>{
                        if (monster.isAnimating) {
                            monster.isAnimating = false;
                            doomdoomTimer = setTimeout(() => {
                                resetDoomDoom(monster);
                            }, (Global.isInstructions?debugVars.timeout+1:debugVars.timeout+4) * 1000)

                            if(Global.isInstructions){
                                setTimeout(() => {
                                    monster.scanned = false
                                }, 15 * 1000)
                            }
                        }
                    }
                })
            }
        })

        // showupTL.to(doomdoom.position,{y:'+=0.1', duration: 0.1, repeat: 5, yoyo: true})

        // showupTL.to(doomdoom.position, { duration: 1.5, x: target.x, y: target.y, z: target.z,
        //     onStart:() =>{
        //         doomdoom.flyingAnim.reset();
        //         doomdoom.flyingAnim.play();
        //         doomdoom.poppingAnim.crossFadeTo(doomdoom.flyingAnim, 0.2);
        //     },
        //     onComplete: ()=>{
        //         // doomdoom.following = true;
        //         doomdoomTimer = setTimeout(()=>{
        //             doomdoom.traverse(el=>{
        //                 if(el.isMesh && el.material){
        //                     showupTL.to(el.material,{opacity:0, duration: 0.6, onComplete: ()=>{
        //                         monster.scanned = false;
        //                         doomdoom.visible = false;
        //                         doomdoom.following = false;
        //                         friendMarkerActive = false;


        //                     }})
        //                 }
        //             })
        //         }, debugVars.timeout*1000)

        //         monster.isAnimating = false;
        //         doomdoom.lookAt(_camera.position);
        //         // doomdoom.mixer.clipAction( doomdoom.clips[0] ).play();
        //         // TODO: Change between animations when lerping/not lerping on following == true
        //         doomdoom.idleAnimation.play();
        //         doomdoom.flyingAnim.crossFadeTo(doomdoom.idleAnimation, 0.3);
        //     }
        // }, 2);
    }

    const kamiDetected = (monster) => {
        // Kami.
        monster.scanned = true;
        monster.isAnimating = true;

        let target = new THREE.Vector3();
        camTargetPostion.getWorldPosition(target);

        kami.lookAt(_camera.position);

        let showupTL = gsap.timeline()
        kami.traverse(el => {
            if (el.isMesh && el.material) {
                el.material.transparent = true
                console.log(el);
                showupTL.fromTo(el.material, { opacity: 0 }, {
                    duration: 0.3, opacity: 1,
                    onStart: () => {
                        kami.poppingAnim.reset();
                        kami.poppingAnim.play();
                        kami.readyToFollow = true;
                        kami.following = true;
                    },
                    onComplete:() =>{
                        if (monster.isAnimating) {
                            monster.isAnimating = false;
                            kamiTimer = setTimeout(() => {
                                resetKami(monster);
                            },  (Global.isInstructions?debugVars.timeout:debugVars.timeout+3) * 1000)
                            if(Global.isInstructions){
                                setTimeout(() => {
                                    monster.scanned = false
                                }, 15 * 1000)
                            }
                        }
                    }
                })

            }
        })

        // todo: replace this for end of kami popping animation
        // showupTL.to(kami.position, { duration: 1, x: target.x, y: target.y, z: target.z,
        //     onStart:() =>{
        //         // kami.flyingAnim.reset();
        //         // kami.flyingAnim.play();
        //         // kami.poppingAnim.crossFadeTo(kami.flyingAnim, 0.2);
        //     },
        //     onComplete: ()=>{
        //     // kami.following = true;
        //     kami.readyToFollow = true;
        //     kamiTimer = setTimeout(()=>{
        //         kami.traverse(el=>{
        //             if(el.isMesh && el.material){
        //                 showupTL.to(el.material,{opacity:0, duration: 0.6, onComplete: ()=>{
        //                     monster.scanned = false;
        //                     kami.visible = false;
        //                     kami.following = false;
        //                     kami.readyToFollow = false;
        //                     friendMarkerActive = false;

        //                 }})
        //             }
        //         })
        //     }, debugVars.timeout*1000)

        //     monster.isAnimating = false;
        //     kami.lookAt(_camera.position);
        //     kami.idleAnimation.play();
        //     kami.flyingAnim.crossFadeTo(kami.idleAnimation, 0.3);
        // }}, 2.3);
    }

    const resetDoomDoom =(monster) => {
        doomdoom.traverse(el => {
            if (el.isMesh && el.material) {
                gsap.to(el.material, {
                    opacity: 0, duration: 0.3, onComplete: () => {
                        // monster.scanned = false;
                        if(!Global.isInstructions) monster.scanned = false;
                        doomdoom.visible = false;
                        doomdoom.following = false;
                        doomdoom.readyToFollow = false;
                        friendMarkerActive = false;
                        Emitter.emit(ONMARKERUNBLOCK)

                        doomdoom.flyingAnim.stop();
                        doomdoom.idleAnimation.stop();
                        doomdoom.poppingAnim.stop();
                    }
                });
            }
        });
    }

    const resetKami = (monster) =>{
        kami.traverse(el => {
            if (el.isMesh && el.material) {
                gsap.to(el.material, {
                    opacity: 0, duration: 0.3, onComplete: () => {
                        if(!Global.isInstructions) monster.scanned = false;
                        kami.visible = false;
                        kami.following = false;
                        kami.readyToFollow = false;
                        friendMarkerActive = false;
                        Emitter.emit(ONMARKERUNBLOCK)

                        kami.flyingAnim.stop();
                        kami.idleAnimation.stop();
                        kami.poppingAnim.stop();

                    }
                });
            }
        });
    }

    const friendsFollowCamera = () => {
        let target = new THREE.Vector3();
        camTargetPostion.getWorldPosition(target);

        // Kami
        if (kami.position.distanceTo(target) > 1 && kami.readyToFollow) {
            // if (!kami.following) {
            //     kami.following = true;
            //     kami.flyingAnim.reset();
            //     kami.flyingAnim.stopFading().crossFadeFrom(kami.idleAnimation, 0.3);
            // }
        }

        if (kami.position.distanceTo(target) < 0.3) {
            if (kami.following) {
                kami.following = false;
                kami.lookAt(_camera.position);
                ;
                kami.idleAnimation.reset();
                kami.idleAnimation.play();
                kami.flyingAnim.stopFading().crossFadeTo(kami.idleAnimation, 0.3);

                // let monster = monsterMap['kami']
                // if (monster.isAnimating) {
                //     monster.isAnimating = false;
                //     kamiTimer = setTimeout(() => {
                //         resetKami(monster);

                //     }, debugVars.timeout * 1000)
                // }
            }
        }

        // DoomDoom
        if (doomdoom.position.distanceTo(target) > 1 && doomdoom.readyToFollow) {
            // if (!doomdoom.following) {
            //     doomdoom.following = true;
            //     doomdoom.flyingAnim.reset();
            //     doomdoom.flyingAnim.stopFading().crossFadeFrom(doomdoom.idleAnimation, 0.3);
            // }
        }

        if (doomdoom.position.distanceTo(target) < 0.3) {
            if (doomdoom.following) {
                doomdoom.following = false;
                doomdoom.lookAt(_camera.position);
                ;
                doomdoom.idleAnimation.reset();
                doomdoom.idleAnimation.play();
                doomdoom.flyingAnim.stopFading().crossFadeTo(doomdoom.idleAnimation, 0.3);

                // let monster = monsterMap['doomdoom']
                // if (monster.isAnimating) {
                //     monster.isAnimating = false;
                //     doomdoomTimer = setTimeout(() => {
                //         resetDoomDoom(monster);
                //     }, debugVars.timeout * 1000)
                // }
            }
        }
        // console.log(kami.position.distanceTo(target), kami.following);

        // TODO: QUATERNION.LERP FOR LOOK AT camTarget

        if (kami.following) kami.position.lerp(target, 0.33)
        if (doomdoom.following) doomdoom.position.lerp(target, 0.33)
    }

    const onUpdate = () => {
        const { delta } = window.XR8.Threejs.xrScene()

        if (doomdoom.mixer) doomdoom.mixer.update(delta)
        if (kami.mixer) kami.mixer.update(delta)
        if (flowerManager) flowerManager.update(delta)

        if (camTargetPostion) friendsFollowCamera()
    }

    const onRender = () => {

    }

    return {
        // Camera pipeline modules need a name. It can be whatever you want but must be
        // unique within your app.
        name: 'monstersImageTrackingScene',

        onStart,
        onUpdate,

        // Listeners are called right after the processing stage that fired them. This guarantees that
        // updates can be applied at an appropriate synchronized point in the rendering cycle.
        listeners: [
            { event: 'reality.imagefound', process: showTarget },
            // { event: 'reality.imageupdated', process: updateTarget },
            { event: 'reality.imagelost', process: hideTarget },
        ],
    }
}

export default imageTrackingMonsters;