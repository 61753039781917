import React, { useEffect, useRef, useState } from "react";
import Detect from "../../util/Detect";
import bg from "../../assets/images/mobile_bg.jpg"
import copy from "../../assets/images/reconsider.png"
import logo from "../../assets/images/experience_logo.svg"

import "./CameraError.scss";
import ios_error_message from "../../assets/images/ios_error_camera.png";
import android_error_message from "../../assets/images/android_error_camera.png";

const CameraError = () => {
  const renderBackground = () => {
    if (Detect.isIOS) {
      return (
        <React.Fragment>
          <img className="bg apple" src="ios_error_message" alt="" />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <img className="bg android" src="" alt="" />
        </React.Fragment>
      );
    }
  };

  const handleClick = () =>{
    window.location.reload()
  }

  return (
    <div className="CameraError">
      <img src={bg} className="bg" alt="" />
      <img src={logo} className="logo" alt="" />
      <div className={`content ${Detect.isIOS?'':'android'}`}>
        <img
          src={Detect.isIOS?ios_error_message:android_error_message}
          alt=""
          onClick={handleClick}
        />
      </div>
    </div>
  );
};

export default CameraError;
