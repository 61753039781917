import gsap from 'gsap';
import './BreadCrumb.scss';
import doomdoomWired from '../../assets/images/doomdoom_wireframe_dropshadow.png'
import doomdoomColor from '../../assets/images/doomdoom_color.png'
import kamiWired from '../../assets/images/kami_wireframe_dropshadow.png'
import kamiColor from '../../assets/images/kami_color.png'
import cokeWired from '../../assets/images/coke_wireframe_dropshadow.png'
import cokeColor from '../../assets/images/coke_color.png'
import flowerWired from '../../assets/images/flower_wireframe_dropshadow.png'
import flowerColor from '../../assets/images/flower_color.png'
import bg from '../../assets/images/gradient_Bar.png'
import Emitter from '../../util/Emitter';
import { ONCHANGESCENE, ONMARKERFOUND } from '../../util/constants';
import Global from '../../util/Global';
import { useEffect, useRef, useState } from 'react';

const BreadCrumb = () => {

    const [doomdoomFound, setDoomdoomFound] = useState(false);
    const [kamiFound, setKamiFound] = useState(false);
    const [cokeFound, setCokeFound] = useState(false);
    const [flowerFound, setFlowerFound] = useState(false);
    const [isVisible, setVisible] = useState(false);

    useEffect(() => {
        Emitter.on(ONMARKERFOUND,({ name, group })=>{
            if(Global.isInstructions) return

            if(name.includes('doomdoom')){
               return setDoomdoomFound(true)
            }
            if(name.includes('kami')){
                return  setKamiFound(true)
            }
            if(name.includes('color_bottle')){
                return  setCokeFound(true)
            }
            if(name.includes('flower')){
                return  setFlowerFound(true)
            }
        })

        Emitter.on(ONCHANGESCENE,(data)=>{
            console.log(data, data != -1);
            if(data != -1 && data != 4){
                setVisible(true);
            }else{
                setVisible(false);
            }
        })
    }, []);

    return (
        <div className={`BreadCrumb ${isVisible?'show':'hide'}`}>
            <img src={bg} className="bg" alt="" />
            <div className='container wired'>
                <img src={doomdoomWired} className={`doomdoom wired ${doomdoomFound?'collected': ''}`} alt="" />
                <span className="divider"></span>
                <img src={kamiWired} className={`kami wired ${kamiFound?'collected': ''}`} alt="" />
                <span className="divider"></span>
                <img src={cokeWired} className={`coke wired ${cokeFound?'collected': ''}`} alt="" />
                <span className="divider"></span>
                <img src={flowerWired} className={`flower wired ${flowerFound?'collected': ''}`} alt="" />
            </div>
            <div className='container color'>
                <img src={doomdoomColor} className={`doomdoom color ${doomdoomFound?'collected': ''}`} alt="" />
                <span className="divider"></span>
                <img src={kamiColor} className={`kami color ${kamiFound?'collected': ''}`} alt="" />
                <span className="divider"></span>
                <img src={cokeColor} className={`coke color ${cokeFound?'collected': ''}`} alt="" />
                <span className="divider"></span>
                <img src={flowerColor} className={`flower color ${flowerFound?'collected': ''}`} alt="" />
            </div>
        </div>
    );
}

export default BreadCrumb;