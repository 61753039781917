/* eslint-disable no-undef */

import { ONPHOTOTAKEN, ONSAVING, ONSAVINGEND, ONSTARTRECORDING, ONSTOPRECORDING, ONTAKEPHOTO } from "../util/constants";
import Emitter from "../util/Emitter";

const mediaRecorderComponent = () => {

    const initRecorderSetup = ({ audioContext, maxDuration }) => {
      // Enable shadows in the renderer.
        // const threejsAudioContext = THREE.AudioContext.getContext()
        console.log("initRecorderSetup");
        XR8.MediaRecorder.configure({
            maxDurationMs: maxDuration,
            enableEndCard: false,

            // END CARD RELATED
            // endCardCallToAction: 'Try it at:',
            // shortLink: '8th.io/my-link',

            // audio related
            audioContext: audioContext || null,
            requestMic: XR8.MediaRecorder.RequestMicOptions.AUTO,
            // configureAudioOutput: userConfiguredAudioOutput,
        })

        initEvents()
    }

    const initEvents = () =>{
        console.log("initEvents");
        dataLayer?.push({'event': 'takeVideoRecording'});
        Emitter.on(ONSTARTRECORDING, ()=>{
            console.log("START recording");
            startRecording()
        })

        Emitter.on(ONSTOPRECORDING, ()=>{
            console.log("STOP recording");
            stopRecording()
        })

        Emitter.on(ONTAKEPHOTO, ()=>{
            takeScreenshot()
        })
        // if(!button){
        //     button = document.createElement('button');
        //     button.style = `
        //         position: fixed;
        //         z-index: 1000;
        //         transform: translateX(-50%);
        //         bottom: 30px;
        //         left: 50%;
        //         width: 50px;
        //         height: 50px;
        //         border: solid 2px rgb(255, 0, 0);
        //         background: transparent;
        //         border-radius: 100%;
        //     `
        //     document.documentElement.append(button);
        // }
        // button.addEventListener('mousedown',startRecording)
        // button.addEventListener('mouseup',stopRecording)
    }

    const videoReadyCallback = (result) =>{
        Emitter.emit(ONSAVINGEND)
        console.log('video result',result);
        // let text = document.getElementById('recording_label')
        // document.documentElement.removeChild(text);
        const newObjectUrl = URL.createObjectURL( result.videoBlob );
        // console.log(result.videoBlob);
        // console.log(newObjectUrl);

        const link = document.createElement('a');
        link.href = newObjectUrl;
        link.download = `Yameii_and_friends_recording.mp4`; //${Date.now().toString()}

        link.style.display = 'none';

        document.body.appendChild(link);

        link.click();
        document.body.removeChild(link);
        //  window.dispatchEvent(new CustomEvent('recordercomplete', {detail: result}))
    }

    const handleRecordingBegining = () =>{
        // let text = document.createElement('h2');
        // text.id ="recording_label"
        // text.textContent = 'recording';
        // text.style = `
        //     position: fixed;
        //     z-index: 1000;
        //     transform: translateX(-50%);
        //     bottom: 50%;
        //     left: 50%;
        //     width: 50px;
        //     height: 50px;
        //     font-size: 30px;
        //     color: #ff0000;
        // `
        // document.documentElement.append(text);
    }

    const showLoading = () =>{
        Emitter.emit(ONSAVING)
        // console.log('...loading');
    }

    const errorHandler = (error) =>{
        Emitter.emit(ONSAVINGEND)
        console.log('error handler',error);
    }

    const onFrameUpdate = ({elapsedTimeMs, maxRecordingMs, ctx}) => {
         // overlay some red text over the video
        //  ctx.fillStyle = 'red'
        //  ctx.font = '50px "Nunito"'
        //  ctx.fillText(`${elapsedTimeMs}/${maxRecordingMs}`, 50, 50)
        //  const timeLeft =  ( 1 - elapsedTimeMs / maxRecordingMs)
        //  // update the progress bar to show how much time is left
        //  progressBar.style.strokeDashoffset = `${100 * timeLeft }`
    }

    const takeScreenshot = () => {

        // OPTION 1
        // const currentConfig = getConfig()
        // XR8.CanvasScreenshot.configure({ maxDimension: 1080, jpgCompression: 50 })

        // window.XR8.CanvasScreenshot.takeScreenshot({
        //   onProcessFrame: ({ctx}) => {
        //     if (currentConfig.onProcessFrame) {
        //       currentConfig.onProcessFrame({ctx})
        //     }
        //   },
        // }).then(
        //   (data) => {
        //     const bytes = atob(data)
        //     const buffer = new ArrayBuffer(bytes.length)
        //     const array = new Uint8Array(buffer)

        //     for (let i = 0; i < bytes.length; i++) {
        //       array[i] = bytes.charCodeAt(i)
        //     }

        //     const blob = new Blob([buffer], {type: 'image/jpeg'})

        //     clearState()
        //     window.dispatchEvent(new CustomEvent('mediarecorder-photocomplete', {detail: {blob}}))
        //   }
        // ).catch(() => {
        //   clearState()
        // })

        // oPTION 2
        // const myOtherCanvas = document.getElementById('canvas2')
        // XR8.CanvasScreenshot.setForegroundCanvas(myOtherCanvas)

        XR8.addCameraPipelineModule(XR8.canvasScreenshot().cameraPipelineModule())
        XR8.canvasScreenshot().takeScreenshot().then(
        data => {
            // myImage is an <img> HTML element
            // const image = document.getElementById('myImage')
            // image.src = 'data:image/jpeg;base64,' + data

            // CREATE BLOB
            const bytes = atob(data)
            const buffer = new ArrayBuffer(bytes.length)
            const array = new Uint8Array(buffer)

            for (let i = 0; i < bytes.length; i++) {
              array[i] = bytes.charCodeAt(i)
            }

            const imgBlob = new Blob([buffer], {type: 'image/jpeg'})
            const imgSrc = 'data:image/jpeg;base64,' + data
            Emitter.emit(ONPHOTOTAKEN,{imgBlob, imgSrc})
        },
        error => {
            console.log(error)
            // Handle screenshot error.
        })

      }

    const startRecording = () =>{
        XR8.MediaRecorder.recordVideo({
            onVideoReady: (result) => videoReadyCallback(result),
            onStart: () => handleRecordingBegining(),
            onStop: () => showLoading(),
            onError: (error) => errorHandler(error),
            onProcessFrame: onFrameUpdate,
            onFinalizeProgress: ({progress, total}) => {
            console.log('Export is ' + Math.round(progress / total) + '% complete')
            },
        })
    }

    const stopRecording = () =>{

        dataLayer?.push({
            'event' : 'arEvent',
            'eventCategory' : 'button',
            'eventAction' : 'clicked',
            'eventLabel' : 'recordVideo'
        })

        dataLayer?.push({'event': 'recordVideo'});

        XR8.MediaRecorder.stopRecording()
    }

    // Return a camera pipeline module that returns a renderer
    return {
      name: 'recordingModule',
      // TODO: THIS NEEDS TO BE AN UPDATE. OR
      onStart: () => {
        initRecorderSetup({ maxDuration: 180000 })  // Add objects set the starting camera position.
      },

    }
  }

export default mediaRecorderComponent;