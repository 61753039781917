import {
    Group, InstancedBufferAttribute,
    InstancedBufferGeometry,
    Mesh,
    PlaneBufferGeometry,
    ShaderMaterial,
    Sprite,
    SpriteMaterial,
    sRGBEncoding, Vector3
} from "three";
import raw from "raw.macro";

const particleVertex = raw("./glsl/burst.vert.glsl");
const particleFragment = raw("./glsl/burst.frag.glsl");

export class ParticleBurst extends Group
{
    static SimpleMode = 0;
    static FlowerMode = 1;

    size = 0.2;

    constructor(mode = ParticleBurst.SimpleMode)
    {
        super();
        this.time = -1;
        this.duration = mode === ParticleBurst.SimpleMode ? 1 : 3;
        this.mode = mode;

        this._initSpriteAnim();
        this._initParticles();
    }

    _initSpriteAnim()
    {
        this.burstMap = window.AssetLoader.getTexture("burst/spritesheet").media;
        this.mainSprite = new Sprite(new SpriteMaterial({
            map: this.burstMap,
            transparent: true,
            depthWrite: false
        }));
        this.mainSprite.scale.setScalar(2);

        const img = this.burstMap.source.data;
        const numSprites = img.naturalWidth / img.naturalHeight;
        this.burstMap.encoding = sRGBEncoding;
        this.burstMap.repeat.set(1.0 / numSprites, 1.0);
        this.numSprites = numSprites;
        this.fps = 12;
        this.visible = false;
        this.add(this.mainSprite);
    }

    trigger()
    {
        this.time = 0;
        this.visible = true;
        this.mainSprite.visible = true;
    }

    update(camera, dt)
    {
        if (this.time < 0 || !this.visible) return;

        const frame = Math.floor(this.fps * this.time);

        if (this.time > this.duration)
            this.visible = false;

        if (frame >= this.numSprites)
            this.mainSprite.visible = false;

        this.burstMap.offset.set(frame / this.numSprites, 0.0);

        this.particleMaterial.uniforms.size.value = this.size;
        this.mainSprite.material.rotation = this.time * 0.01;
        this.particleMaterial.uniforms.time.value = this.time;
        const alpha = this.time / this.duration;
        this.particleMaterial.uniforms.opacity.value = 1.0 - alpha * alpha * alpha;

        this.time += dt;
    }

    _initParticles()
    {
        this.starMap = window.AssetLoader.getTexture("burst/starsheet").media;
        const img = this.starMap.source.data;
        const numSprites = img.naturalWidth / img.naturalHeight;

        const geom = new InstancedBufferGeometry().copy(new PlaneBufferGeometry());
        this.particleMaterial = new ShaderMaterial({
            vertexShader: particleVertex,
            fragmentShader: particleFragment,
            depthWrite: false,
            transparent: true,
            uniforms: {
                map: { value: this.starMap },
                size: { value: 0.2 },
                time: { value: 0 },
                gravity: { value: 2.98 },
                opacity: { value: 1 },
                numSprites: { value: numSprites }
            }
        });

        this.particles = new Mesh(geom, this.particleMaterial);
        this.particles.frustumCulled = false;

        const count = this.mode === ParticleBurst.SimpleMode ? 20 : 40;
        geom.instanceCount = count;

        const velocities = new Float32Array(count * 3);
        const spriteIndices = new Float32Array(count);
        const v = new Vector3();

        const rangePhi = this.mode === ParticleBurst.SimpleMode? Math.PI : 0.5;
        const speed = this.mode === ParticleBurst.SimpleMode? 3 : 2;
        for (let i = 0; i < count; ++i) {
            v.setFromSphericalCoords(Math.random() * .5 + .5, Math.random() * rangePhi, Math.random() * Math.PI * 2).multiplyScalar(speed);
            v.toArray(velocities, i * 3);
            spriteIndices[i] = i % numSprites;
        }

        geom.setAttribute("velocity", new InstancedBufferAttribute(velocities, 3, false));
        geom.setAttribute("spriteIndex", new InstancedBufferAttribute(spriteIndices, 1, false));

        this.add(this.particles);
    }
}