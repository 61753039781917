import raw from "raw.macro";
import {ShaderMaterial, Vector3} from "three";
import {QueryString} from "../../util/QueryString";

const vertexShader = raw("./glsl/orbit.vert.glsl");
const fragmentShader = raw("./glsl/orbit.frag.glsl");

export class OrbitMovement extends ShaderMaterial
{
    constructor()
    {
        super({
            vertexShader, fragmentShader,
            uniforms: {
                tDiffuse: {value: null},
                tPosition: {value: null},
                dt: {value: 0},
                force: {value: 0.1 },
                center: {value: new Vector3(0, 0, 0) }
            }
        });
    }
}