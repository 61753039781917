import {WebGLRenderTarget} from "three";

export class PingPongRT
{
    constructor(width, height, options)
    {
        this._src = new WebGLRenderTarget(width, height, options);
        this._dst = new WebGLRenderTarget(width, height, options);
    }

    get sourceTexture()
    {
        return this._src.texture;
    }

    get renderTarget()
    {
        return this._dst;
    }

    swap()
    {
        const tmp = this._src;
        this._src = this._dst;
        this._dst = tmp;
    }
}