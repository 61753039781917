/* eslint-disable no-undef */
import gsap from 'gsap';
import { CustomEase } from 'gsap/all';
import './ARUI.scss';
import { useStore } from '../../hook/useStore';
import Emitter from '../../util/Emitter';
import { useEffect, useRef, useState } from 'react';
import Global from '../../util/Global';

import doomdoomHelp from '../../assets/images/doomdoom_help.png'
import kamiHelp from '../../assets/images/kami_help.png'
import cokeHelp from '../../assets/images/coke_help.png'
import flowerHelp from '../../assets/images/flower_help.png'

import helpIcon from '../../assets/images/help_icon.svg'
import recenterIcon from '../../assets/images/recenter_icon.svg'
import shutterIcon from '../../assets/images/shutter_icon.svg'
import shutterRecIcon from '../../assets/images/shutter_icon_rec.svg'
import selfieIcon from '../../assets/images/rotate-camera.png' //Icons made by Ilham Fitrotul Hayat https://www.flaticon.com/authors/ilham-fitrotul-hayat from "https://www.flaticon.com/"
import closeIcon from '../../assets/images/close.svg'
import bgImage from '../../assets/images/mobile_bg.jpg'
import logo from '../../assets/images/experience_logo.svg'
import yameiEnd from '../../assets/images/end_yameii.png'

import btnBg from '../../assets/images/btn_bg.png'
import endBg from '../../assets/images/mobile_bg.jpg'

import { ONCHANGESCENE, ONPHOTOTAKEN, ONSAVING, ONSAVINGEND, ONSTARTRECORDING, ONSTOPRECORDING, ONTAKEPHOTO } from '../../util/constants';

const ARUI = () => {
    const [{ }, { setCurrentSection, setSectionIndex, setStartAR }] = useStore();
    const [showHelp, setShowHelp] = useState(true);

    const helpDuration = 8;
    const helpUIRef = useRef();
    const helpBtnRef = useRef();
    const shutterBtnRef = useRef();
    const swapBtnRef = useRef();
    const swapModuleAdded = useRef(false);
    const recenterBtnRef = useRef();
    const startingSoonRef = useRef();
    const wrapperRef = useRef();
    const loadingDots = useRef();
    const [swapIndicator, setSwapIndicator] = useState(false);
    const [isRecording, setIsRecording] = useState(false)
    const [isSaving, setIsSaving] = useState(false)

    const currentHelp = useRef(0)

    const endScreenRef = useRef()
    const btnGroupRef = useRef()
    const previewRef = useRef()
    const shareRef = useRef()
    const blobRef = useRef();
    const isHolding = useRef()
    const isHoldingTimer = useRef()
    const isRecordingRef = useRef(false)
    const isFront = useRef(false);

    const swapCamera = (e) => {
        if(!Global.isEndMode) return


        e.stopPropagation()
        gsap.set(swapBtnRef.current,{clearProps: 'scale'})
        gsap.to(swapBtnRef.current,{scale: 0.9, duration: 0.15,repeat: 1, yoyo: true})
        XR8.stop()
        setSwapIndicator(true)
        if(!swapModuleAdded.current){
            swapModuleAdded.current = true
            XR8.addCameraPipelineModule({
                name: 'detectChange',
                onStart: () => {
                    console.log("started swap");
                    setSwapIndicator(false)
                },
            })
        }
        if (isFront.current) {
            window.XR8.XrController.configure({
                disableWorldTracking: true, //Global.debugOnDesktop
            })

            XR8.removeCameraPipelineModules(['reality', 'worldscene','particleBurstModule','monstersImageTrackingScene', 'general-ar-tracking-scene', 'bubblesModule', 'cokeCapModule'])
            XR8.run({canvas: document.getElementById('camerafeed'), cameraConfig: {direction: XR8.XrConfig.camera().BACK}})
        } else {
            XR8.removeCameraPipelineModules(['reality', 'worldscene','particleBurstModule','monstersImageTrackingScene', 'general-ar-tracking-scene', 'bubblesModule', 'cokeCapModule'])
            window.XR8.XrController.configure({
                disableWorldTracking: true, //Global.debugOnDesktop
            })
          // run the 8th wall engine using the front camera
          XR8.run({canvas: document.getElementById('camerafeed'), cameraConfig: {direction: XR8.XrConfig.camera().FRONT}})
        }
        isFront.current = !isFront.current
    }

    // gsap.registerPlugin(CustomEase)
    // const customEase = CustomEase.create("custom", "M0,0 C0,0 0.114,0.409 0.147,0.678 0.158,0.771 0.165,0.82 0.178,0.912 0.185,0.957 0.189,0.981 0.198,1.025 0.205,1.056 0.208,1.073 0.217,1.103 0.223,1.126 0.227,1.141 0.236,1.162 0.242,1.175 0.248,1.183 0.256,1.194 0.259,1.198 0.263,1.2 0.268,1.202 0.272,1.204 0.276,1.205 0.28,1.205 0.285,1.204 0.289,1.202 0.293,1.2 0.298,1.197 0.301,1.195 0.305,1.19 0.315,1.18 0.322,1.173 0.33,1.16 0.356,1.122 0.369,1.098 0.395,1.059 0.406,1.042 0.413,1.032 0.427,1.017 0.439,1.003 0.449,0.994 0.463,0.984 0.475,0.975 0.485,0.971 0.499,0.967 0.508,0.964 0.515,0.964 0.524,0.963 0.534,0.963 0.541,0.963 0.552,0.965 0.597,0.975 0.597,0.973 0.64,0.996 0.67,1.012 0.672,1.013 0.7,1.016 0.723,1.017 0.763,1.006 0.788,1.005 0.849,1.004 0.832,1 0.893,0.999 0.935,0.998 1,1 1,1 ")
    const onClickClaimGift = (e) =>{
        e.stopPropagation()

        dataLayer?.push({
            'event' : 'arEvent',
            'eventCategory' : 'button',
            'eventAction' : 'clicked',
            'eventLabel' : 'cokeStudioLink'
        })
        dataLayer?.push({'event': 'linkToCokeStudio'});
        window.open('https://us.coca-cola.com/coachella', '_blank')
    }
    const onClickSelfie = (e) =>{
        e.stopPropagation()
        console.log("click on selfie");
        dataLayer?.push({'event': 'takePhotoWithYameii'});

        dataLayer?.push({
            'event' : 'arEvent',
            'eventCategory' : 'button',
            'eventAction' : 'clicked',
            'eventLabel' : 'takePhotoWithYameii'
        })

        // window.open('https://coke.com', '_blank')
        gsap.to(endScreenRef.current,{ autoAlpha: 0, duration: 0.3})

    }

    const onClickShare = (e) =>{
        e.stopPropagation()
        console.log("share Selfie!");
        dataLayer?.push({'event': 'sharePhoto'});
        dataLayer?.push({
            'event' : 'arEvent',
            'eventCategory' : 'button',
            'eventAction' : 'clicked',
            'eventLabel' : 'sharePhoto'
        })

        const files = [new File([blobRef.current], 'yammeiPhoto.jpg', { type: blobRef.current.type })];
			const shareData = {
				title: 'Yameii and Friends',
				files,
			};
            console.log("share Selfie!", shareData);
			if (navigator.canShare(shareData)) {
				try {
					navigator.share(shareData);
				} catch (err) {
					if (err.name !== 'AbortError') {
						console.error(err.name, err.message);
					}
				}
			} else {
				console.warn('Sharing not supported', shareData);
			}
        // window.open('https://coke.com', '_blank')
    }

    const closeShare = (e) =>{
        e.stopPropagation()
        console.log("close share");
        gsap.to(btnGroupRef.current,{ autoAlpha: 1, duration: 0.2,delay: 0.3})
        gsap.to(shareRef.current,{ autoAlpha: 0, duration: 0.2})
        // window.open('https://coke.com', '_blank')
    }


    const onClickHelp = (e) => {
        //    set state for show help
        e.stopPropagation()
        gsap.set(helpBtnRef.current,{clearProps: 'scale'})
        gsap.to(helpBtnRef.current,{scale: 0.9, duration: 0.1,repeat: 1, yoyo: true})
        setShowHelp(!showHelp)
    }

    const onCaptureDown = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if(Global.isEndMode){
            console.log('captrue BUTTON DOWN');
            e.stopPropagation()
            Emitter.emit(ONTAKEPHOTO)
            gsap.to(btnGroupRef.current,{ autoAlpha: 0, duration: 0.2})
            setTimeout(() => {
                gsap.to(shareRef.current,{ autoAlpha: 1, duration: 0.2})
                gsap.to(endScreenRef.current,{ autoAlpha: 1, duration: 0.4})
            }, 300);
        }else{
            if(isHolding.current){
                isHolding.current = false;
                Emitter.emit(ONSTOPRECORDING)
                console.log('off');
                setIsRecording(false)
                isRecordingRef.current = false

            }else{
                Emitter.emit(ONSTARTRECORDING)
                console.log('on');
                setIsRecording(true)
                isRecordingRef.current = true
                isHolding.current = true;
                isHoldingTimer.current = setTimeout(() => {
                    isHolding.current = false;
                }, 2000);
            }
        }

        //    set state for show help
    }

    const onCaptureUp = (e) => {
        e.stopPropagation()
        if(Global.isEndMode){

        }else{
            if(isHolding.current){
                clearTimeout(isHoldingTimer.current)

            }else{
                Emitter.emit(ONSTOPRECORDING)
                console.log('off');
                setIsRecording(false)
            }
            // Emitter.emit(ONSTOPRECORDING)
            // console.log('off');
            // setIsRecording(false)
        }
        //    set state for show help
    }

    const onClickRecenter = (e) => {
        e.stopPropagation()
        gsap.set(recenterBtnRef.current,{clearProps: 'scale'})
        gsap.to(recenterBtnRef.current,{scale: 0.9, duration: 0.15,repeat: 1, yoyo: true})
        window.XR8.XrController.recenter()
        //    set state for show help
    }

    const closeUI = (e) =>{
        e.stopPropagation()
        console.log("close");
        setShowHelp(false)
        Global.isFirstTutorialEnabled = false;
    }

    const showEndScreen = () => {
        console.log("ebnd screen ", isRecordingRef.current);
        if(isRecordingRef.current){
            isHolding.current = false;
            Emitter.emit(ONSTOPRECORDING)
            console.log('off');
            setIsRecording(false)
            isRecordingRef.current = false
        }
        gsap.to([helpBtnRef.current, recenterBtnRef.current], { duration: 0.3, y: '-=20', autoAlpha: 0 });
        gsap.to(endScreenRef.current, { autoAlpha: 1, duration: 0.3, delay: 0.2 });
        gsap.to(swapBtnRef.current, { autoAlpha: 1, duration: 0.3, delay: 0.2, pointerEvents: 'all'});
    }

    useEffect(()=>{
        if(showHelp){
            gsap.to([helpBtnRef.current, shutterBtnRef.current, recenterBtnRef.current],{pointerEvents: 'none', duration: 0.3, y: '20px',opacity:0, stagger:0.08})
        }else{
            gsap.to([helpBtnRef.current, shutterBtnRef.current, recenterBtnRef.current],{pointerEvents: 'all', duration: 0.3, y: '0px',opacity:1, stagger:0.08})
        }
    },[showHelp])

    // INSTRUCTIONS LOOP ANIAMTION
    useEffect(()=>{
        let loopInterval = setInterval(() => {
            gsap.to([...wrapperRef.current.children],{opacity: 0, duration: 0.3, repeatDelay: 2, onComplete:()=>{
                gsap.to(wrapperRef.current.children[currentHelp.current],{opacity: 1, duration: 0.3})
            }})

            currentHelp.current += 1;
            if(currentHelp.current>=4){
                currentHelp.current = 0
            }
        }, 2000);

        return ()=>{
            clearInterval(loopInterval)
        }
    },[])



    useEffect(() => {

        Emitter.on(ONSAVING, ()=>{
            setIsSaving(true)
            // setTimeout(() => {
            //     setIsSaving(false)
            // }, 5000);
        })

        Emitter.on(ONSAVINGEND, ()=>{
            setIsSaving(false)
        })

        Emitter.on(ONPHOTOTAKEN,({imgBlob, imgSrc})=>{
            // console.log(imgBlob);
            blobRef.current = imgBlob
            previewRef.current.src = imgSrc
        })

        //! -1 = practice / 0 = day / 1 = night / 2 = ASMR / 3 = flowers / 4 = end.
        Emitter.on(ONCHANGESCENE,(data)=>{
            if(data == 0){
                gsap.to(startingSoonRef.current,{ autoAlpha: 1, duration: 0.3, onComplete:()=>{
                    gsap.to(startingSoonRef.current,{ autoAlpha: 0, duration: 0.3, delay: 3})
                }})
                return
            }

            if(data == 5){
                console.log("set endmode");
                Global.isEndMode = true;
                // Set is finished / enable selfie mode.
                showEndScreen();
                return
            }
        })

        // initial build up animation
        gsap.fromTo([...loadingDots.current.children],{opacity:0}, {opacity:1, duration: 0.2, stagger:0.5, repeat: -1, repeatDelay: 0.5})
        gsap.set(swapBtnRef.current, { autoAlpha: 0, pointerEvents: 'none'});
        var tl = gsap.timeline({delay: .5});
        tl.from(helpUIRef.current,{duration: 0.4, delay:0, opacity:0, visibility: 'hidden'})
        tl.set(helpUIRef.current,{clearProps: 'all'})
        tl.from([helpUIRef.current.children[0],helpUIRef.current.children[1]],{duration: 0.3, y: '-=10',opacity:0, stagger:0.2,
            onComplete: ()=>{
                setTimeout(() => {
                    // console.log('hi');
                    // isFirstTutorialEnabled
                    Global.isFirstTutorialEnabled = false;
                    setShowHelp(false)
                    gsap.set(helpUIRef.current.children[2], {opacity: 1, pointerEvents: 'all'})
                    // tl.from([helpBtnRef.current, shutterBtnRef.current, recenterBtnRef.current],{duration: 0.3, y: '+=20',opacity:0, stagger:0.08})
                }, helpDuration*1000);
            }}, 0.7)
    }, []);

    return (
        <section className="ARUI" onClick={closeUI}>
            <div ref={helpUIRef} className={`helpUI ${showHelp?'show':'hide'}`} onClick={(e)=>e.stopPropagation()}>
                <p>FIND THESE ITEMS DURING<br/>THE PERFORMANCE TO<br/>TRIGGER THE AR<br/>EXPERIENCE</p>
                {/* <img src={helpGif} alt="" /> */}
                <div ref={wrapperRef} className='marker-wrapper'>
                    <img src={doomdoomHelp} style={{opacity: 1}} alt="" />
                    <img src={kamiHelp} alt="" />
                    <img src={cokeHelp} alt="" />
                    <img src={flowerHelp} alt="" />
                </div>
                <img src={closeIcon} className="close-btn" onClick={closeUI} alt="" />
            </div>

            <div ref={helpBtnRef} className='help-button btn' onClick={onClickHelp}>
                <img src={helpIcon} alt="" />
            </div>
            <div ref={shutterBtnRef} className={`capture-button`} onTouchStart={onCaptureDown} onTouchEnd={onCaptureUp} onTouchCancel={onCaptureUp}>
                <img src={shutterIcon} alt="" />
                <img src={shutterRecIcon} alt="" className='rec' style={{opacity: isRecording?1:0}} />
            </div>
            <div ref={recenterBtnRef} className='recenter-button btn' onClick={onClickRecenter}>
                <img src={recenterIcon} alt="" />
            </div>

            <div ref={swapBtnRef} className='swap-camera-button btn' onClick={swapCamera}>
                <img src={selfieIcon} alt="Icons made by Ilham Fitrotul Hayat at flaticon" />
            </div>

            <div ref={startingSoonRef} className="startingSoon">
                <img src={bgImage} className="bg" alt="" />
                <img src={logo} className="logo" alt="" />
                <h1>THE SHOW IS<br/>ABOUT TO<br/>BEGIN...</h1>
            </div>

            <h1 className='download-hint' style={{opacity: isSaving?'1':'0'}}>DOWNLOADING<span ref={loadingDots}><span>.</span><span>.</span><span>.</span></span></h1>

            <div ref={endScreenRef} className='endScreen'>
                <img src={endBg} className="bg" alt="" />
                <img src={logo} className="logo" alt="" />
                <img src={yameiEnd} className="yameiiEnd" alt="" />
                <div className='btn-group' ref={btnGroupRef}>
                    <p className='copy'>
                        #cokestudioyameii
                    </p>
                    <button>
                        <img src={btnBg} className='btn-bg' onClick={onClickClaimGift} alt="" />
                        <span>CLAIM A GIFT</span>
                    </button>
                    <button>
                        <img src={btnBg} className='btn-bg' onClick={onClickSelfie} alt="" />
                        <span>TAKE A PHOTO WITH ME</span>
                    </button>
                </div>

                <div ref={shareRef} className='shareUI'>
                    <img src={closeIcon} className="share-close-btn" onClick={closeShare} alt="" />
                    <img ref={previewRef} className='preview' src="" alt="" />
                    <p className='hash'>#COKESTUDIOYAMEII</p>
                    <button>
                        <img src={btnBg} className='btn-bg' onClick={onClickShare} alt="" />
                        <span>SHARE YOUR PHOTO</span>
                    </button>
                </div>
            </div>
            <div className={`black-overlay ${swapIndicator?'visible':'hidden'}`}>
                loading...
            </div>
        </section>
    );
}

export default ARUI;