import raw from "raw.macro";
import {ShaderMaterial} from "three";

const vertexShader = raw("./glsl/curlnoise.vert.glsl");
const fragmentShader = raw("./glsl/curlnoise.frag.glsl");

export class CurlNoiseMovement extends ShaderMaterial
{
    constructor()
    {
        super({
            vertexShader, fragmentShader,
            uniforms: {
                tDiffuse: {value: null},
                tPosition: {value: null},
                dt: {value: 0},
                force: {value: 2 },
                scale: {value: 20 }
            }
        });
    }
}