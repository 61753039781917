/* eslint-disable no-undef */

import {QueryString} from "../util/QueryString";
import {Bubbles} from "../three/bubbles/Bubbles";
import Emitter from "../util/Emitter";
import {EXPLODE_BUBBLE, ONMARKERFOUND} from "../util/constants";
import Debugger from "../util/Debugger";
import {BubbleExplosion} from "../three/bubbles/BubbleExplosion";
import Global from "../util/Global";

export const bubblesModule = () => {
    // init stuff
    let bubbles;
    let effervescent;

    function initDebugUI(renderer)
    {
        const folder = Debugger.gui.addFolder("Bubbles");
        folder.add(bubbles, "force").min(0).max(.1).name("Force")
        folder.add(bubbles, "globalScale").min(0).max(5).name("Global Scale")
        folder.add(bubbles, "sizeScale").min(0).max(5).name("Size Scale")
        folder.add(effervescent, "globalScale").min(0).max(5).name("Global Scale Eff")
        folder.add(effervescent, "sizeScale").min(0).max(5).name("Size Scale Eff")

        if (QueryString.debug) {
            renderer.domElement.addEventListener("click", event => {
                Emitter.emit(EXPLODE_BUBBLE);
            });
        }
    }

    // Return a camera pipeline module that returns a renderer
    return {
        name: 'bubblesModule',
        onStart: () => {
            const {renderer, envMap} = getXRScene();  // Get the 3js scene from XR8.Threejs

            bubbles = new BubbleExplosion(renderer);
            bubbles.envMap = envMap;
            bubbles.sizeScale = 1.3;

            effervescent = new BubbleExplosion(renderer);
            effervescent.envMap = envMap;
            effervescent.sizeScale = 1.7;

            initDebugUI(renderer);

            /*Emitter.on(EXPLODE_BUBBLE, () => {
                bubbles.phase = BubbleExplosion.PhaseExplode;
            });*/

            Emitter.on(ONMARKERFOUND, ({ name, group }) => {
                // From juan: using the include instead of === as we may need to use 2 markers to boost detection (one with text, one without text)
                if (name.includes("color_bottle")) {
                    group.add(bubbles);
                    bubbles.reset();
                    bubbles.phase = BubbleExplosion.PhaseBuild;

                    group.add(effervescent);
                    effervescent.reset();
                    effervescent.phase = BubbleExplosion.PhaseEffervescent;
                }
            });
        },
        onUpdate: () => {
            let {camera, delta} = getXRScene();
            // console.log(delta);
            if (Global.isInstructions)
                delta *= 13 / 7;

            if (bubbles.parent)
                bubbles.update(camera, delta);
            if (effervescent.parent)
                effervescent.update(camera, delta);
        },


    }
}

function getXRScene()
{
    return window.XR8?.Threejs?.xrScene() ?? window.Stage3D
}
