// GENERAL
export const ONASSETLOADED = 'on_asset_loaded'
export const ONASSETSLOADED = 'on_assets_loaded'
export const ONGETGPUTIER = 'on_get_gpu_tier'
export const ONSTART = 'on_start'

export const SETARPOSITION = 'on_set_ar_position'

export const ONCANVASRESIZE = 'on_canvas_resize'

// INDEX UI
export const ONCHANGEINDEX = 'on_change_index'

// AR EVENTS
export const ONRESTART = 'on_restart'
export const ONCAMERAERROR = 'on_camera_error'
export const ONCHANGESCENE = 'on_change_scene'
export const ONCAMERAREADY = 'camera-ready'

// RECORDING
export const ONSTARTRECORDING = 'on_start_recording'
export const ONSTOPRECORDING = 'on_stop_recording'
export const ONTAKEPHOTO = 'on_take_photo'
export const ONPHOTOTAKEN = 'on_photo_taken'
export const ONSAVING = 'on_saving'
export const ONSAVINGEND = 'on_saving_end'

// Markers
export const ONMARKERFOUND = 'on_marker_found'
export const ONMARKERLOST = 'on_marker_lost'
export const ONMARKERBLOCK = 'on_marker_block'
export const ONMARKERUNBLOCK = 'on_marker_unblock'

// WebGL
export const ONWEBGLACTIVATE = 'on_webgl_activate'
export const ONWEBGLDEACTIVATE = 'on_webgl_deactivate'

export const DRAW_BUBBLE = 'draw_bubble'
export const EXPLODE_BUBBLE = 'explode_bubble'