import {
    Material,
    Mesh,
    OrthographicCamera,
    PlaneBufferGeometry,
    ShaderMaterial,
    Texture,
    WebGLRenderTarget,
} from 'three'
import { CopyShader } from 'three/examples/jsm/shaders/CopyShader'

let mesh
let planeGeom
let copyCamera
let copyMaterial

export function getOrthoCam() {
    if (!copyCamera) {
        copyCamera = new OrthographicCamera(-1, 1, 1, -1)
        copyCamera.position.set(0, 0, 1)
    }

    return copyCamera
}

export function getRectMesh() {
    if (!mesh) {
        planeGeom = new PlaneBufferGeometry(2, 2)
        mesh = new Mesh(planeGeom, null)
    }

    return mesh
}

export function getCopyMaterial() {
    if (!copyMaterial) {
        // @ts-ignore
        copyMaterial = new ShaderMaterial({ ...CopyShader, depthWrite: false, depthTest: false, type: 'copyMaterial' })
    }
    return copyMaterial
}

export function renderRect(renderer, source, target, customMaterial = null, clear = true, hook) {
    const copyCamera = getOrthoCam()
    const oldRT = renderer.getRenderTarget()
    const material = customMaterial ?? getCopyMaterial()
    const mesh = getRectMesh()
    if (source) material.uniforms.tDiffuse.value = source
    mesh.material = material

    const autoClear = renderer.autoClear

    renderer.autoClear = clear
    renderer.setRenderTarget(target)
    if (hook) hook();
    renderer.render(mesh, copyCamera)
    renderer.setRenderTarget(oldRT)
    renderer.autoClear = autoClear
}

export function renderPingPong(renderer, target, material, clear = true)
{
    renderRect(renderer, target.sourceTexture, target.renderTarget, material, clear)
    target.swap();
}