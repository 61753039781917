// import gsap from 'gsap'
// import { Vector3 } from 'three';
import { Mesh, MeshBasicMaterial, Object3D, PlaneGeometry, Raycaster, Vector2, Vector3 } from 'three';
import Debugger from '../../../util/Debugger';
import Flower from './Flower';

class FlowerSpawner {
    constructor(scene, camera){
        this.scene = scene
        this.camera = camera
        this.init()
    }

    init() {
        this.planePlaced = false
        this.hitPositions = []
        this.flowers = []
        this.spawing = false
        this.currentTime = 0;

        this.debugVars = {
            movementGap: 0.5,
            flowerGap: 0.6,
            maxFlowerCount: 15,
            minSize:  0.8,
            maxSize: 2,
            lifeSpan: 4,
            flowerDistance: 7,
        }

        this.initDebugUI()
        // this.timerTreshold = 0.8;
        // this.maxFlowerCount = 10
        // this.prevSpawnPosition = new Vector3()

        this.planeCollider = new Mesh(new PlaneGeometry(300,20), new MeshBasicMaterial({color: 'red', wireframe: true}))
        this.planeCollider.name = "flowerSpawnerPlaneCollision"
        this.planeCollider.visible = false;
        // this.scene.add(this.planeCollider)

        this.raycaster = new Raycaster();
    }


    initDebugUI = ()=>{
        const folder = Debugger.gui.addFolder("Flowers");
        folder.add(this.debugVars,'movementGap', 0.1, 1, 0.05);
        folder.add(this.debugVars,'flowerGap', 0.1, 0.8, 0.05);
        folder.add(this.debugVars,'maxFlowerCount', 10, 30,1);
        folder.add(this.debugVars,'minSize', 0, 2, 0.1);
        folder.add(this.debugVars,'maxSize', 0, 3, 0.1);
        folder.add(this.debugVars,'lifeSpan', 1, 10, 1);
        folder.add(this.debugVars,'flowerDistance', 2, 7, 0.5);
    }

    onMarkerDetected(detail){
        // if(this.planePlaced) return;
        this.deleteAllFlowers();
        this.spawnInArch();

        // this.planePlaced = true;
        // this.planeCollider.position.copy(detail.position);
        // this.planeCollider.quaternion.copy(detail.rotation);
        // this.planeCollider.visible = false;
        // setTimeout(() => {
        //     this.spawing = true;
        // }, 300);
    }

    stopSpawning(){
        this.spawing = false;
        this.planePlaced = false;
        // this.deleteDeathFlower();
    }

    spawnOnTutorial(detail){
        // let newPost = new Vector3().copy(detail.position)
        // // newPost.y = 0

        // let posVector = new Vector3()
        // const dir = posVector.subVectors(newPost, this.camera.position ).normalize();
        // const distance = (this.camera.position.z - 5) / dir.z;
        // const finalPos = this.camera.position.clone().add(dir.multiplyScalar(distance));
        var helperObject = new Object3D()
        helperObject.position.copy( this.camera.position );
        helperObject.rotation.copy( this.camera.rotation );
        helperObject.translateZ( -1*this.debugVars.flowerDistance );

        let finalPos =  new Vector3().copy(helperObject.position)
        finalPos.y -= 2
        this.spawnFlowerWithScale(finalPos,3.5)
    }

    spawnInArch(){
        var helperObject = new Object3D()
        helperObject.position.copy( this.camera.position );
        helperObject.rotation.copy( this.camera.rotation );
        // helperObject.updateMatrix();  // this line seems unnecessary
        helperObject.translateZ( -1*this.debugVars.flowerDistance );

        // Left flowers
        for (let li = 0; li < 10; li++) {
            setTimeout(() => {
                let z = 1.2 * (1 - Math.cos((Math.PI/2)*(li/12)));
                helperObject.translateZ(z)
                let posL =  new Vector3().copy(helperObject.position)
                let posR =  new Vector3().copy(helperObject.position)
                posL.x -= li*this.debugVars.flowerGap//? maybe use flowerGap
                posL.y = 0;
                posR.x += li*this.debugVars.flowerGap//? maybe use flowerGap
                posR.y = 0;
                // pos.z -= Math.sin((Math.PI/2)*li/12);
                // helperObject.translateZ(z );

                this.spawnFlower(posL)

                if(li != 0){
                    this.spawnFlower(posR)
                }

            }, li*100);
            // remove timeout and add a delay to grow

        }

        // Right flowers
        // for (let ri = 0; ri < 12; ri++) {
        //     let pos =  new Vector3().copy(helperObject.position)
        //     pos.x += ri*0.3 //? maybe use flowerGap
        //     pos.y = 0;
        //     pos.z -= 1 * (1 - Math.cos((Math.PI/2)*(ri/12)));
        //     console.log(ri,pos.z);
        //     this.spawnFlower(pos)

        // }
    }

    spawnWithGaze(){
        if(this.flowers.length > this.debugVars.maxFlowerCount) return

        this.raycaster.setFromCamera( new Vector2(), this.camera );
        let intersects = this.raycaster.intersectObjects([this.planeCollider]);

        if(intersects[0]){
            // todo: maybe prevent colissions under y<0 so flowers don't appear super close.
            let point = intersects[0].point;
            // point.z += this.debugVars.flowerDistance
            point.y = this.camera.position.y;
            // let posVector = new Vector3()
            // const dir = posVector.subVectors(this.camera.position, point ).normalize();
            // const distance = (this.camera.position.z - randomDistance) / dir.z;
            // const finalPos = this.camera.position.clone().add(dir.multiplyScalar(distance));
            // finalPos.y = 0

            // if hit postion then randomize
            if(!this.isCloseToFlowers(point)){
                this.hitPositions.push(point)
                console.log('added Point', point);
                let randomDistance = Math.floor(Math.random() * ((this.debugVars.flowerDistance+this.debugVars.flowerGap) - (this.debugVars.flowerDistance-this.debugVars.flowerGap)) + (this.debugVars.flowerDistance-this.debugVars.flowerGap));
                let posVector = new Vector3()
                const dir = posVector.subVectors(point, this.camera.position ).normalize();
                const distance = (this.camera.position.z - randomDistance) / dir.z;
                const finalPos = this.camera.position.clone().add(dir.multiplyScalar(distance));
                finalPos.y = 0
                this.spawnFlower(finalPos)
            }

        }
    }

    isCloseToFlowers (currentPoint){
        for (let index = 0; index < this.hitPositions.length; index++) {
            const element  = this.hitPositions[index];
            if(element.distanceTo(currentPoint) < this.debugVars.movementGap){
                return true;
            }
        }
        // for (let index = 0; index < this.flowers.length; index++) {
        //     const element  = this.flowers[index];
        //     if(element.position.distanceTo(currentPoint) < this.debugVars.flowerGap){
        //         return true;
        //     }
        // }
        return false;
    }

    spawnFlower(position){
        let nScale = Math.random()* (this.debugVars.maxSize - this.debugVars.minSize) + this.debugVars.minSize
        let _flower = new Flower(position, nScale, this.debugVars.lifeSpan)

        _flower.addToScene(this.scene)
        _flower.lookAtCamera(this.camera)
        _flower.playGrowAnimation()
        // console.log(_flower);
        this.flowers.push(_flower)
    }

    spawnFlowerWithScale(position, scale){
        let _flower = new Flower(position, scale, 1)

        _flower.addToScene(this.scene)
        _flower.lookAtCamera(this.camera)
        _flower.playGrowAnimation()
        // console.log(_flower);
        this.flowers.push(_flower)
    }

    deleteAllFlowers() {
        console.log('delete ALL');
        this.flowers.forEach(element => {
            element.isDeath = true
        });
        for (let i = 0; i < this.flowers.length; i++) {
            let element = this.flowers[i];
            // this.scene.remove(element)
            element.deleteFromScene(this.scene);
            element = null;

        }
        this.flowers = []
        this.hitPositions = []
        // window.XR8.XrController.recenter()
    }

    killAllFlowers(){
        this.flowers.forEach(element => {
            element.isDeath = true
        });
    }

    deleteDeathFlower(){

        let newArray = this.flowers.filter((flower) => {
            return !flower.isDeath;
        });

        if(newArray.length < this.flowers.length){
            this.flowers = newArray;
        }
    }

    setPlaneCollision(position){

    }

    // setPosition(){
    // }

    update (delta){
        // if(this.spawing){
        //     this.currentTime+= delta;
        //     this.spawnWithGaze()
        // }

        for (let i = 0; i < this.flowers.length; i++) {
            const element = this.flowers[i];
            element.update(delta)
            if(element.isDeath){
                element.deleteFromScene(this.scene);
            }
        }

        this.deleteDeathFlower();
    }

}

export default FlowerSpawner