import wavemap from "../assets/textures/wavemap.png";
import distmap from "../assets/textures/distmap.png";
import envmapDaytime from '../assets/textures/hdr/daytime_panorama_specular.hdr';
import envmapSunset from '../assets/textures/hdr/sunset_panorama_specular.hdr';
import envmapNight from '../assets/textures/hdr/night_panorama_specular.hdr';
import envmapDawn from '../assets/textures/hdr/dawn_panorama_specular.hdr';
import burstSpriteSheet from '../assets/textures/burst/burst-sheet.png';
import burstStarSheet from '../assets/textures/burst/star-particle-sheet.png';
import bottleMask from '../assets/textures/bottle-mask.png';
import cokeCapModel from '../assets/models/coke_cap.glb';

const RESOURCES = {
  images: [
    // { id: 'twitter', url: 'images/twitter.png', description: 'twitter' },
    // { id: 'smaa-search', url: SMAAEffect.searchImageDataURL, description: '' },
    // { id: 'smaa-area', url: SMAAEffect.areaImageDataURL, description: '' },
    // { id: 'dust', url: 'static/images/warp/dust.png', description: '' },
  ],
  textures: [
    { id: 'wavemap', url: wavemap },
    { id: 'distmap', url: distmap },
    { id: 'envmap/day', url: envmapDaytime },
    { id: 'envmap/sunset', url: envmapSunset },
    { id: 'envmap/night', url: envmapNight },
    { id: 'envmap/dawn', url: envmapDawn },
    { id: 'burst/spritesheet', url: burstSpriteSheet, init: true },
    { id: 'burst/starsheet', url: burstStarSheet, init: true },
    { id: 'mask/bottleMask', url: bottleMask, init: true },
  ],
  sounds: [
    // { id: 'intro', url: 'audio/intro.mp3', analyser: false },
  ],
  videos: [
    // { id: 'microsoft', url: 'videos/menu/test.mp4' },
  ],
  models: [
    { id: 'doomdoom', url: 'model/doomdoom_v4.glb', init: true },
    // { id: 'flower_stem', url: 'model/flower-v2.glb', init: true }, //may be used to have different color flowrs.
    { id: 'flower_stem', url: 'model/flower-v3.glb', init: true },
    { id: 'kami_green', url: 'model/kami_yellow_v4.glb', init: true },
    { id: 'puppet_bag', url: 'model/puppet_bag.glb', init: true },
    { id: 'coke_cap', url: cokeCapModel, init: true },
    // { id: 'cube', url: 'models/cube/cube2.gltf' },
    // { id: 'instance', url: 'models/testinstance/test.gltf' },
  ],
  jsons: [
    // { id: 'projects', url: 'data/projects.json' }
  ],
  fontTextures: [
    // {
    //   id: 'stratos',
    //   json: 'font-textures/stratos/StratosLCWeb-Regular.json',
    //   image: 'font-textures/stratos/msdf.png'
    // },
  ],
  fonts: [
    // { id: 'playfairdisplay' }
  ]
};

export default RESOURCES;