import {
    AddEquation,
    AdditiveBlending,
    CullFaceNone,
    CustomBlending, DoubleSide, MinEquation,
    NormalBlending, OneFactor,
    RepeatWrapping,
    ShaderMaterial,
    Vector2
} from "three";
import raw from "raw.macro";

const vertexShader = raw("./glsl/metaball.vert.glsl");
const fragmentShader = raw("./glsl/metaball.frag.glsl");
const metaInc = raw("./glsl/metaball.inc.glsl");

export class MetaballMaterial extends ShaderMaterial
{
    constructor(srcMaterial)
    {
        const waveMap = window.AssetLoader.getTexture("wavemap").media;
        waveMap.wrapS = RepeatWrapping;
        waveMap.wrapT = RepeatWrapping;

        super({
            fragmentShader,
            vertexShader: vertexShader.replace("#include <metaball>", metaInc),
            uniforms: {
                lifetime: { value: 3.0 },
                growTime: { value: 1.0 },
                time: { value: 0.0 },
                timeScale: { value: 0.25  },
                positionMap: { value: null },
                positionMapSize: { value: new Vector2() },
                waveMap: { value: waveMap },
                distMap: { value: window.AssetLoader.getTexture("distmap").media },
                rippleStrength: { value: 5 },
                rippleScale: { value: new Vector2(1.0, 0.5).multiplyScalar(10) },
                globalScale: { value: 1.0 },
                sizeScale: { value: 1.0 }
            },
            blending: CustomBlending,
            blendSrc: OneFactor,
            blendDst: OneFactor,
            blendSrcAlpha: OneFactor,
            blendDstAlpha: OneFactor,
            blendEquation: AddEquation,
            blendEquationAlpha: AddEquation,
            transparent: true,
            depthWrite: false,
            // side: DoubleSide,
        });

        if (srcMaterial) {
            this.uniforms.lifetime = srcMaterial.uniforms.lifetime;
            this.uniforms.growTime = srcMaterial.uniforms.growTime;
            this.uniforms.time = srcMaterial.uniforms.time;
            this.uniforms.positionMap = srcMaterial.uniforms.positionMap;
            this.uniforms.positionMapSize = srcMaterial.uniforms.positionMapSize;
        }
    }
}