import {Color} from "three";

export class RenderStateStack
{
    constructor(renderer)
    {
        this._renderer = renderer;
        this._stack = [];
    }

    push(rt, clearColor, clearAlpha)
    {
        const r = this._renderer;
        this._stack.push({
            rt: r.getRenderTarget(),
            color: r.getClearColor(new Color()),
            alpha: r.getClearAlpha(),
            autoClear: r.autoClear
        });

        r.setRenderTarget(rt);
        if (clearColor)
            r.setClearColor(clearColor);
        if (clearAlpha !== undefined)
            r.setClearAlpha(clearAlpha);
    }

    pop()
    {
        const s = this._stack.pop();
        const r = this._renderer;
        r.setRenderTarget(s.rt);
        r.setClearColor(s.color);
        r.setClearAlpha(s.alpha);
        r.autoClear = s.autoClear;
    }
}