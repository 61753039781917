import {MathUtils, Vector3} from "three";
import {Bubbles} from "./Bubbles";
import {randomRange, randomSigned} from "../../util/random";
import {getPositionWithFixedDistance} from "../utils/getPositionWithFixedDistance";

const pos = new Vector3();
const vel = new Vector3();

export class BuildBlobStreamPhase
{
    count = 0;

    lifetime = 3;
    growTime = 1;
    movementMode = Bubbles.MODE_CURL_NOISE;

    force = 0;
    time = 0;

    emergencePos = new Vector3();
    crawlTime = 0;


    buildUpDuration = 0;
    // emergenceDuration = 4;
    crawlDuration = 10;
    effervescenceDuration = 6;
    lastEmergencePos = new Vector3();
    lastCrawlPos = new Vector3();

    constructor(effervescence = false)
    {
        this.spawnBuildUp = this.spawnBuildUp.bind(this);
        // this.spawnEmergence = this.spawnEmergence.bind(this);
        this.spawnEffervescence = this.spawnEffervescence.bind(this);
        this.spawnCrawl = this.spawnCrawl.bind(this);

        if (effervescence) {
            this.crawlDuration = 0;
            this.effervescenceDuration = 2;
        }
    }

    reset()
    {
        this.time = 0;
        this.crawlTime = 0;
        this.count = 0;
        this.emergencePos.set(0, 0, 0);
    }

    update(camera, bubbles, dt)
    {
        this.time += dt;
        let rate;
        let func;

        const crawlTime = this.buildUpDuration;
        const effervescenceTime = crawlTime + this.crawlDuration;
        const endTime = effervescenceTime + this.effervescenceDuration;

        if (this.time > endTime) {
            rate = 0;
        }
        else if (this.time > effervescenceTime) {
            rate = 100;
            func = this.spawnEffervescence;
        }
        else if (this.time > crawlTime) {
            rate = 50;
            func = this.spawnCrawl;
        }
        else {
            rate = 50;
            func = this.spawnBuildUp;
        }

        const count = this.count + dt * rate;
        // used to split the dt's into smaller substeps
        const start = Math.ceil(this.count);
        const end = Math.ceil(count);
        const dc = end - start;

        for (let i = start; i < end; ++i) {
            func(camera, bubbles, dt / dc);
        }

        this.count = count;
    }

    spawnBuildUp(camera, bubbles, dt)
    {
        const size = randomRange(0.1, 0.2);

        pos.x = randomSigned(0.2);
        pos.y = randomSigned(0.6);
        pos.z = randomSigned(0.2);

        vel.set(0, 0, 0)

        bubbles.spawn(pos, size, vel);

        this.force = 0;
    }

    /*spawnEmergence(camera, bubbles, dt)
    {
        const size = randomRange(0.07, 0.15);

        this.emergenceTime += dt;
        const t = this.emergenceTime / this.emergenceDuration;
        // make it come halfway towards the user
        mat4.copy(bubbles.matrixWorld).invert();
        camera.getWorldPosition(pos).applyMatrix4(mat4).multiplyScalar(Math.sqrt(t) * 0.9);

        // direction of travel:
        mat4.lookAt(Vector3Constants.ORIGIN, pos, Vector3Constants.Y_AXIS);
        mat4.setPosition(pos);
        const radius = t * 0.1;
        pos.x = Math.cos(this.emergenceTime) * radius;
        pos.y = Math.sin(this.emergenceTime) * radius;
        pos.z = 0;

        // keep track so we know where to begin next phase
        this.lastEmergencePos.copy(pos);

        pos.x += randomSigned(0.1);
        pos.y += randomSigned(0.1);
        pos.z += randomSigned(0.1);

        pos.applyMatrix4(mat4);

        vel.set(randomSigned(), randomSigned(), randomSigned()).multiplyScalar(0.1 * t);

        bubbles.spawn(pos, size, vel);

        this.force = MathUtils.smoothstep(t, 0.0, 0.2) * 0.05;
    }*/

    spawnCrawl(camera, bubbles, dt)
    {
        const size = randomRange(0.07, 0.15);
        const radius = 0.5;

        this.crawlTime += dt;

        const t = Math.sqrt(this.crawlTime / this.crawlDuration);
        const distanceToCam = MathUtils.lerp(3.0, 2.0, t);
        getPositionWithFixedDistance(distanceToCam, bubbles, camera, pos);

        pos.x += Math.sin(this.crawlTime * 1.5) * radius;
        pos.y += Math.sin(this.crawlTime * 1.3) * radius;
        pos.z += Math.sin(this.crawlTime) * radius;

        this.lastCrawlPos.copy(pos);

        pos.x += randomSigned(0.1);
        pos.y += randomSigned(0.1);
        pos.z += randomSigned(0.1);

        vel.set(randomSigned(), randomSigned(), randomSigned()).multiplyScalar(0.01);

        bubbles.spawn(pos, size, vel);

        this.force = 0.2;
    }

    spawnEffervescence(camera, bubbles, dt)
    {
        let size = randomRange(0.015, 0.05);

        if (this.crawlDuration > 0)
            pos.copy(this.lastCrawlPos);
        else {
            size *= 1.5;
            getPositionWithFixedDistance(2, bubbles, camera, pos)
        }
        pos.x += randomSigned(0.2);
        pos.y += randomSigned(0.2);
        pos.z += randomSigned(0.2);

        vel.set(randomSigned(), randomSigned(), randomSigned()).multiplyScalar(this.crawlDuration > 0 ? 0.2 : 0.1);

        bubbles.spawn(pos, size, vel);

        this.force = this.crawlDuration > 0 ? 10 : 2;
    }
}