import {CubeMapMixer} from "./CubeMapMixer";

export class DaytimeEnvMapController
{
    _daytime = 0;

    constructor(renderer, maps)
    {
        this.maps = maps;
        this.cubeMapRenderer = new CubeMapMixer(renderer, maps[0], maps[1]);
    }

    get daytime()
    {
        return this._daytime;
    }

    set daytime(value)
    {
        this._daytime = value;

        let f = value * (this.maps.length - 1);
        const i = Math.floor(f);
        f -= i;
        this.cubeMapRenderer.mapA = this.maps[i];
        this.cubeMapRenderer.mapB = this.maps[(i + 1) % this.maps.length];
        this.cubeMapRenderer.ratio = f;
    }

    get texture()
    {
        return this.cubeMapRenderer.texture;
    }

    update()
    {
        this.cubeMapRenderer.update();
    }
}