import {
    BoxBufferGeometry,
    CubeCamera,
    DoubleSide, Mesh,
    Scene,
    ShaderMaterial,
    WebGLCubeRenderTarget
} from "three";
import raw from "raw.macro";

const vertexShader = raw("./glsl/blendcube.vert.glsl");
const fragmentShader = raw("./glsl/blendcube.frag.glsl");

export class CubeMapMixer extends WebGLCubeRenderTarget
{
    _ratio = 0;
    needsUpdate = true;

    constructor(renderer, mapA, mapB)
    {
        const source = mapA.source.data[0];
        super(source.width, { type: source.type, format: source.format, depth: false, stencil: false });

        this.renderer = renderer;
        this.mapA = mapA;
        this.mapB = mapB;
        this.cubeCam = new CubeCamera(0.01, 1.0, this);
        this.scene = new Scene();
        const cube = new BoxBufferGeometry();
        this.material = new ShaderMaterial({
            vertexShader, fragmentShader, side: DoubleSide,
            uniforms: {
                mapA: { value: mapA },
                mapB: { value: mapB },
                blend: { value: 0 },
            }
        });
        this.scene.add(new Mesh(cube, this.material));
    }

    update()
    {
        if (!this.needsUpdate) return;

        this.material.uniforms.blend.value = this._ratio;
        this.material.uniforms.mapA.value = this.mapA;
        this.material.uniforms.mapB.value = this.mapB;
        this.cubeCam.update(this.renderer, this.scene);

        this.needsUpdate = false;
    }

    get ratio()
    {
        return this._ratio;
    }

    set ratio(value)
    {
        this.needsUpdate = this._ratio !== value;
        this._ratio = value;
    }
}