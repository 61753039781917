import {AnimationMixer, LoopOnce, Vector3} from 'three'
import {clone} from 'three/examples/jsm/utils/SkeletonUtils.js';
import gsap from'gsap'
class Flower {
    constructor(position, scale, lifespan){
        this.loadedAsset = window.AssetLoader.getModel('flower_stem').media
        this.model = clone(this.loadedAsset.scene)
        this.mixer = new AnimationMixer(this.model);

        this.clips = this.loadedAsset.animations
        this.idleClip = Math.random() > 0.4 ? this.clips[1] : this.clips[2] //choose a random idel animation

        this.growAnim = this.mixer.clipAction(this.clips[0])
        this.growAnim.timeScale = 1.5
        this.growAnim.clampWhenFinished = true
        this.growAnim.setLoop(LoopOnce)

        this.idleAnimation = this.mixer.clipAction(this.idleClip)

        this.position = new Vector3().copy(position);
        this.scale = scale || Math.random()* (1.5 - 0.7) + 0.7
        this.lifespan = lifespan
        this.isDeath = false;
        this.addEvents();
        this.intModel();
    }

    addEvents(){
        this.mixer.addEventListener( 'finished', (e) => {
            // console.log(e.action === this.growAnim);
            // console.log(e.action == this.growAnim);

            if(e.action === this.growAnim){
                this.idleAnimation.play();
                this.growAnim.crossFadeTo(this.idleAnimation, 0.6)

                setTimeout(() => {
                    gsap.to(this.material,{opacity: 0, duration: 1, onComplete:()=>{
                        // removeThisObject
                        this.isDeath = true;
                        this.model.visible = false;
                    }})
                }, this.lifespan*1000 + 1000);
            }
        });
    }

    intModel(){
        this.model.traverse(el=>{
            if(el.isMesh || el.type === 'Mesh'){
                el.castShadow = true;

                if(el.material){
                    this.material = el.material.clone()
                    this.material.transparent = true;

                    el.material = this.material
                }
            }
        })

    }

    deleteFromScene(scene){
        scene.remove(this.model)
    }

    addToScene(scene){
        scene.add(this.model)
        this.model.position.copy(this.position)
        this.model.scale.setScalar(this.scale);
    }

    lookAtCamera(camera){
        this.model.lookAt(camera.position.x, 0, camera.position.z)
    }

    playGrowAnimation(){
        this.growAnim.play();
    }

    update (delta){
        if(this.mixer) this.mixer.update(delta)
    }
}

export default Flower