/* eslint-disable no-undef */

import Emitter from "../util/Emitter";
import {ONMARKERFOUND} from "../util/constants";
import {MathUtils, Matrix3, Vector3} from "three";
import {getPositionWithFixedDistance} from "../three/utils/getPositionWithFixedDistance";
import Global from "../util/Global";

export const cokeCapModule = () => {
    let cap;
    let force = new Vector3();
    let vel = new Vector3();
    let time = 0;

    const rotationSpeed = 1.0;
    const forceStrength = 0.2;
    const initSpeed = 1.0;
    const maxVelocity = 10.0;
    let duration = 14;
    const scale = 0.05;

    // Return a camera pipeline module that returns a renderer
    return {
        name: 'cokeCapModule',
        onStart: () => {
            const {camera, envMap} = getXRScene();

            cap = window.AssetLoader.getModel("coke_cap").media.scene;
            cap.scale.setScalar(scale);

            cap.traverse(obj => {
                if (obj.isMesh) {
                    obj.material.envMap = envMap;
                }
            })

            Emitter.on(ONMARKERFOUND, ({name, group}) => {

                duration = Global.isInstructions? 10 : 14;

                // TODO: Change marker ID
                // this is in camera space
                const angle = Math.random() * Math.PI * 2.0;
                vel.set(Math.sin(angle), Math.cos(angle), -2.0).normalize().multiplyScalar(initSpeed);

                // move to world space
                const mat3 = new Matrix3().setFromMatrix4(group.matrixWorld).invert();
                vel.transformDirection(camera.matrixWorld);
                vel.applyMatrix3(mat3);

                getPositionWithFixedDistance(3.0, group, camera, cap.position);

                if (name.includes("color_bottle")) {
                    group.add(cap);
                    time = 0;
                }
            });
        },
        onUpdate: () => {
            if (!cap.parent) return

            let {camera, delta} = getXRScene();

            if (Global.isInstructions)
                delta *= 9 / 7;

            if (time > duration) {
                cap.parent.remove(cap);
                return;
            }

            time += delta;
            const s = 1.0 - MathUtils.smoothstep(time, duration * .9, duration);
            cap.scale.setScalar(s * scale);

            // force points from cap toward camera
            camera.getWorldPosition(force);
            cap.parent.worldToLocal(force);
            force.sub(cap.position);

            vel.addScaledVector(force, forceStrength * delta);
            const len = vel.lengthSq();
            if (len > maxVelocity * maxVelocity) {
                vel.normalize().multiplyScalar(maxVelocity)
            }

            cap.position.addScaledVector(vel, delta);
            cap.rotation.x += delta * rotationSpeed;
            cap.rotation.y += delta * rotationSpeed;
            cap.rotation.z += delta * rotationSpeed;
        },
    }
}

function getXRScene()
{
    return window.XR8?.Threejs?.xrScene() ?? window.Stage3D
}
